import React from "react";
import { useEffect, useState } from "react";

import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import "./ClassOverview.css";

import AssessmentStatus from "./AssessmentStatus";
import ShareClassButton from "./ShareClassButton";
import AddStudentsButton from "./AddStudentsButton";
import ClassPersonnel from "./ClassPersonnel";
import ClassStudents from "./ClassStudents";
import SimpleNavigatioButton from "./SimpleNavigationButton";
import GradeCard from "./GradeCard";
import ClassSettingsButton from "./ClassSettingsButton";
import ViewQRLogins from "./ViewQRLogins";

import { getClassAssessments, getStudentResults } from "../services/APIClient";

function ClassOverview(props) {
  // Class Personnel
  let group = props.group;
  let classDetails = props.classDetails;
  let classid = classDetails.id;
  let gradeLevel = props.gradeLevel;
  let name = props.name;
  let teachers = props.teachers;
  let sharedWith = props.sharedWith;
  let students = props.students;
  let qrCodeDetails = props.qrCodeDetails;
  let dateCreated = classDetails.date_created;
  let yearCreated = dateCreated.slice(0, 4);
  let gradeLevel1 = classDetails.grade_level1;
  let gradeLevel2 = classDetails.grade_level2;
  let trialClass = classDetails.trial_class;
  let rollNumber = props.rollNumber;
  let teacherEmail = props.teacherEmail;
  let schoolid = props.schoolid;
  let isSpecialEdClass = props.isSpecialEdClass;
  let teacherRoles = props?.teacherRoles;

  const [classAssessments, setClassAssessments] = useState([]);
  const [loading, setLoading] = useState(true);

  const classAssessmentsValues = Object.values(classAssessments);

  // console.log(yearCreated)

  useEffect(() => {
    getClassAssessments(classid).then((response) => {
      setClassAssessments(response).finally(() => {
        setLoading(false);
      });
    });
  }, [classid]);

  return (
    <div className="class-overview-container">
      <Container className="container-shape">
        <Row className="page-description-bar">
          <Col className="col-7">
            <Row className="inline-block">
              <Col className="col-6">
                <h2 className="page-description-header">
                  {group.name} Overview
                </h2>
                <h2 className="year-header">Class Created In: {yearCreated}</h2>
              </Col>
              <Col className="col-3">
                <GradeCard
                  gradeLevel={classDetails.grade_level}
                  gradeLevel1={gradeLevel1}
                  gradeLevel2={gradeLevel2}
                  trialClass={trialClass}
                />{" "}
              </Col>
              <Col className="col-1">
                <ClassSettingsButton
                  group={group}
                  classDetails={classDetails}
                  teachers={teachers}
                  sharedWith={sharedWith}
                  gradeLevel={gradeLevel}
                  name={name}
                  students={students}
                  qrCodeDetails={qrCodeDetails}
                  teacherRoles={teacherRoles}
                />
              </Col>
            </Row>
            <p className="paragraph-font-size">
              See the class students, class personnel and what assessments are
              active in the class.
            </p>
          </Col>
          <Col className="col-1"></Col>
          <Col className="col-4">
            <Row>
              <Col className="col-6"></Col>
              <Col className="col-6">
                <SimpleNavigatioButton
                  path="/classadmindashboard"
                  label="Back"
                  colour="blue"
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <hr />
        <Row>
          <Row className="centre-buttons-classoverview">
            <Col className="col-4">
              <ShareClassButton
                classDetails={classDetails}
                teachers={teachers}
                sharedWith={sharedWith}
                name={name}
                gradeLevel={gradeLevel}
                rollNumber={rollNumber}
                teacherEmail={teacherEmail}
                schoolid={schoolid}
              />
            </Col>
            <Col className="col-4">
              <AddStudentsButton
                classDetails={classDetails}
                teachers={teachers}
                sharedWith={sharedWith}
                name={name}
                gradeLevel={gradeLevel}
                schoolid={schoolid}
                isSpecialEdClass={isSpecialEdClass}
              />
            </Col>
            <Col className="col-4">
              <ViewQRLogins
                name={name}
                label="View/Print QR Codes"
                gradeLevel={gradeLevel}
                qrCodeDetails={qrCodeDetails}
              />
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col className="col-6 vertical-border">
              <ClassStudents
                students={students}
                classDetails={classDetails}
                teachers={teachers}
                qrCodeDetails={qrCodeDetails}
                schoolid={schoolid}
                teacherRoles={teacherRoles}
              />
            </Col>
            <Col className="col-6 right-side-container">
              <ClassPersonnel
                classOwnerId={group.classOwnerId}
                classDetails={classDetails}
                teachers={teachers}
                sharedWith={sharedWith}
              />
              <br></br>
              <Col></Col>
            </Col>
          </Row>
        </Row>
      </Container>
    </div>
  );
}

export default ClassOverview;
