import React from "react";
import { Link, useNavigate } from "react-router-dom";

function ReturnToSencoResults(props) {
  const {
    classDetails,
    classAssessments,
    teachers,
    assessment,
    name,
    gradeLevel,
    schoolid,
    teacherid,
    allClassesAssessments,
  } = props;

  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1); // Navigates to the previous page
  };

  console.log(allClassesAssessments, "ACA");

  return (
    <button className="blue-button" onClick={handleBackClick}>
      Back
    </button>
  );
}

export default ReturnToSencoResults;
