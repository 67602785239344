import React, { useEffect, useState } from "react";
import { Row, Col, Form, Modal } from "react-bootstrap";
import {
  updateStudent,
  deleteStudent,
  getSchools,
  getClasses,
  addAdditionalClass, // Import the addAdditionalClass function
  deleteAdditionalClass, // Import the deleteAdditionalClass function
} from "../services/APIClient";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "./StudentProfileForm.css";

function StudentProfileForm(props) {
  const student = props.student;
  const studentId = student.id;
  const studentName = student.name;
  const eslStatus = student.esl;
  const senStatus = student.sen;
  let schoolid = props.schoolid;
  let classDetails = props.classDetails;
  let teacherRoles = props.teacherRoles;

  console.log(classDetails.id);

  const [updateStudentName, setUpdateStudentName] = useState(studentName);
  const [updateEslStatus, setUpdateEslStatus] = useState(eslStatus);
  const [updateSenStatus, setUpdateSenStatus] = useState(senStatus);
  const [updateAsdStatus, setUpdateAsdStatus] = useState(student.asd || false);
  const [updateGddStatus, setUpdateGddStatus] = useState(student.gdd || false);
  const [updateSendStatus, setUpdateSendStatus] = useState(
    student.send || false
  );
  const [countryId, setCountryId] = useState(null);
  const [schoolClasses, setSchoolClasses] = useState([]); // State for all classes
  const [setClasses, setSetClasses] = useState([]); // State for SET classes
  const [selectedSetClassId, setSelectedSetClassId] = useState(""); // State for selected SET class

  useEffect(() => {
    getSchools()
      .then((response) => {
        const matchingSchool = response.data.find(
          (school) => school.id === schoolid
        );

        if (matchingSchool) {
          const countryid = matchingSchool.countryid;
          setCountryId(countryid); // Set country ID
        }
      })
      .catch((error) => {
        console.error("Error fetching schools:", error);
      });
  }, [schoolid]);

  // New useEffect to fetch classes
  useEffect(() => {
    getClasses()
      .then((response) => {
        console.log(response, "CLASSES");
        setSchoolClasses(response); // Set the fetched classes to state
        // Filter for SET classes
        const filteredSetClasses = response.filter(
          (cls) => cls.set_class === true
        );
        setSetClasses(filteredSetClasses); // Set filtered SET classes
      })
      .catch((error) => {
        console.error("Error fetching classes:", error);
      });
  }, []); // Empty dependency array means this runs once on mount

  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  // Hardcoded country IDs
  const irelandId = "07d45b79-24b1-435f-b82a-93861b23a86e";
  const englandId = "74bb2897-dbbe-41e3-b547-caee866545cf";

  const handleModalClose = () => setShow(false);
  const handleModalOpen = () => setShow(true);

  useEffect(() => {
    // Handle state updates when props change
    setUpdateStudentName(studentName);
    setUpdateEslStatus(eslStatus);
    setUpdateSenStatus(senStatus);
    setUpdateAsdStatus(student.asd || false);
    setUpdateGddStatus(student.gdd || false);
    setUpdateSendStatus(student.send || false);
  }, [
    studentName,
    eslStatus,
    senStatus,
    student.asd,
    student.gdd,
    student.send,
  ]);

  const handleEslCheck = (e) => setUpdateEslStatus(e.target.checked);
  const handleSenCheck = (e) => setUpdateSenStatus(e.target.checked);
  const handleAsdCheck = (e) => setUpdateAsdStatus(e.target.checked);
  const handleGddCheck = (e) => setUpdateGddStatus(e.target.checked);
  const handleSendCheck = (e) => setUpdateSendStatus(e.target.checked);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = {
      name: updateStudentName,
      esl: updateEslStatus,
      sen: updateSenStatus,
      asd: updateAsdStatus,
      gdd: updateGddStatus,
      send: updateSendStatus,
    };

    try {
      const response = await updateStudent(studentId, data);
      console.log("Update successful:", response);

      // Call addAdditionalClass if a SET class is selected
      if (selectedSetClassId) {
        await addAdditionalClass(studentId, selectedSetClassId);
      }

      navigate(-2);
    } catch (error) {
      console.error("Update failed:", error);
      alert("Failed to update student. Please try again.");
    }
  };

  const deletePupil = async () => {
    try {
      await deleteStudent(studentId);
      navigate(-2);
    } catch (error) {
      console.error("Failed to delete student:", error);
    }
  };

  const removeFromSetClass = async () => {
    try {
      await deleteAdditionalClass(studentId, classDetails.id); // Adjust this according to your API
      setSelectedSetClassId(""); // Reset the selected class
      toast.success("Student successfully removed from SET class!"); // Show success toast
      navigate(-2); // Navigate back to the previous page
    } catch (error) {
      console.error("Failed to remove from SET class:", error);
      toast.error("Failed to remove student from SET class. Please try again."); // Show error toast
    }
  };

  return (
    <>
      <Row>
        <Form className="form" onSubmit={handleSubmit}>
          <Form.Group as={Row} className="mb-3" controlId="name">
            <Form.Label className="label" column sm="2">
              <strong>Name:</strong>
            </Form.Label>
            <Col sm="6">
              <Form.Control
                value={updateStudentName}
                size="md"
                type="text"
                onChange={(e) => setUpdateStudentName(e.target.value)}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" controlId="esl">
            <Form.Label className="label" column sm="2">
              <strong>ESL</strong>
            </Form.Label>
            <Col>
              <Form.Check
                type="checkbox"
                onChange={handleEslCheck}
                checked={updateEslStatus}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" controlId="sen">
            <Form.Label className="label" column sm="2">
              <strong>Speech & Language</strong>
            </Form.Label>
            <Col>
              <Form.Check
                type="checkbox"
                onChange={handleSenCheck}
                checked={updateSenStatus}
              />
            </Col>
          </Form.Group>

          {/* Conditionally render based on hardcoded country IDs */}
          {(countryId === irelandId || countryId === englandId) && (
            <>
              <Form.Group as={Row} className="mb-3" controlId="asd">
                <Form.Label className="label" column sm="2">
                  <strong>ASD</strong>
                </Form.Label>
                <Col>
                  <Form.Check
                    type="checkbox"
                    onChange={handleAsdCheck}
                    checked={updateAsdStatus}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3" controlId="gdd">
                <Form.Label className="label" column sm="2">
                  <strong>GDD</strong>
                </Form.Label>
                <Col>
                  <Form.Check
                    type="checkbox"
                    onChange={handleGddCheck}
                    checked={updateGddStatus}
                  />
                </Col>
              </Form.Group>
            </>
          )}

          {countryId === englandId && (
            <Form.Group as={Row} className="mb-3" controlId="send">
              <Form.Label className="label" column sm="2">
                <strong>SEND</strong>
              </Form.Label>
              <Col>
                <Form.Check
                  type="checkbox"
                  onChange={handleSendCheck}
                  checked={updateSendStatus}
                />
              </Col>
            </Form.Group>
          )}

          {classDetails.set_class ? (
            // Show 'Remove from SET Class' if the current class is a SET class
            <button
              type="button"
              className="float-right red-button"
              onClick={removeFromSetClass}
            >
              Remove from SET Class
            </button>
          ) : (
            // Show 'Add Student to a SET Class' only if teacherRoles includes 'SENCO'
            teacherRoles.includes("SENCO") && (
              <Form.Group as={Row} className="mb-3" controlId="setClass">
                <Form.Label className="label" column sm="2">
                  <strong>Add to a SET Class:</strong>
                </Form.Label>
                <Col sm="6">
                  <Form.Select
                    value={selectedSetClassId}
                    onChange={(e) => setSelectedSetClassId(e.target.value)}
                  >
                    <option value="">Select a SET Class</option>
                    {setClasses.map((cls) => (
                      <option key={cls.id} value={cls.id}>
                        {cls.name}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              </Form.Group>
            )
          )}

          <button
            type="button"
            className="float-right red-button"
            onClick={handleModalOpen}
          >
            Delete
          </button>
          <button
            type="button"
            className="float-right white-button"
            onClick={() => navigate(-1)}
          >
            Back
          </button>
          <button type="submit" className="float-right green-button">
            Update
          </button>
        </Form>
      </Row>

      <Modal show={show} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Student - {studentName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete <strong>{studentName}</strong>? After
          deleting this student you will <strong>not</strong> be able to access
          their assessment history.
        </Modal.Body>
        <Modal.Footer>
          <button className="white-button" onClick={handleModalClose}>
            Cancel
          </button>
          <button className="red-button" onClick={deletePupil}>
            Delete
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default StudentProfileForm;
