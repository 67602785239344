import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import "./AdditionalColleaguesFunction.css";

function AdditionalColleaguesFunction({
  setColleagues,
  classes,
  loading,
  error,
  setHasInvalidEmails,
  fromCreateClass,
}) {
  // Initializing colleagueEntries without id for now
  const [colleagueEntries, setColleagueEntries] = useState([
    {
      firstName: "",
      lastName: "",
      email: "",
      sharedClassId: "",
      emailError: "",
    },
  ]);

  // To track whether we've prepopulated the class dropdown
  const [hasPrepopulated, setHasPrepopulated] = useState(false);

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleInputChange = (index, field, value) => {
    const updatedEntries = colleagueEntries.map((entry, i) => {
      if (i === index) {
        if (field === "email" && !isValidEmail(value)) {
          return {
            ...entry,
            [field]: value,
            emailError: "Invalid email address",
          };
        } else {
          return { ...entry, [field]: value, emailError: "" };
        }
      }
      return entry;
    });

    setColleagueEntries(updatedEntries);
  };

  const handleClassChange = (index, value) => {
    const updatedEntries = colleagueEntries.map((entry, i) =>
      i === index ? { ...entry, sharedClassId: value } : entry
    );
    setColleagueEntries(updatedEntries);
  };

  const addColleague = () => {
    setColleagueEntries([
      ...colleagueEntries,
      {
        firstName: "",
        lastName: "",
        email: "",
        sharedClassId: "",
        emailError: "",
      },
    ]);
  };

  const removeColleague = (index) => {
    setColleagueEntries(colleagueEntries.filter((_, i) => i !== index));
  };

  useEffect(() => {
    setColleagues(colleagueEntries);
  }, [colleagueEntries, setColleagues]);

  useEffect(() => {
    const hasInvalidEmails = colleagueEntries.some((entry) => entry.emailError);
    setHasInvalidEmails(hasInvalidEmails);
  }, [colleagueEntries, setHasInvalidEmails]);

  const uniqueClasses = Array.from(new Set(classes.map((cls) => cls.id))).map(
    (id) => classes.find((cls) => cls.id === id)
  );

  // Sort classes by date_created to find the most recent
  const mostRecentClass = uniqueClasses.sort(
    (a, b) => new Date(b.date_created) - new Date(a.date_created)
  )[0];

  // Prepopulate the sharedClassId with the most recent class ID if fromCreateClass is true
  useEffect(() => {
    if (fromCreateClass && mostRecentClass && !hasPrepopulated) {
      const updatedEntries = colleagueEntries.map((entry) => ({
        ...entry,
        sharedClassId: mostRecentClass.id,
      }));
      setColleagueEntries(updatedEntries);
      setHasPrepopulated(true); // Ensure it only runs once
    }
  }, [fromCreateClass, mostRecentClass, hasPrepopulated]);

  return (
    <>
      {loading ? (
        <p>Loading classes...</p>
      ) : error ? (
        <p>Error loading classes: {error.message}</p>
      ) : (
        colleagueEntries.map((entry, index) => (
          <div key={index} className="input-row">
            <Form.Control
              className="add-colleagues-form-fields"
              type="text"
              placeholder="First Name"
              value={entry.firstName}
              onChange={(e) =>
                handleInputChange(index, "firstName", e.target.value)
              }
            />
            <Form.Control
              className="add-colleagues-form-fields"
              type="text"
              placeholder="Last Name"
              value={entry.lastName}
              onChange={(e) =>
                handleInputChange(index, "lastName", e.target.value)
              }
            />
            <Form.Control
              className="add-colleagues-form-fields"
              type="email"
              placeholder="Email (School/Work Address)"
              value={entry.email}
              onChange={(e) =>
                handleInputChange(index, "email", e.target.value)
              }
              isInvalid={!!entry.emailError}
            />
            <Form.Control.Feedback type="invalid">
              {entry.emailError}
            </Form.Control.Feedback>
            <Form.Control
              as="select"
              className="select-field"
              value={entry.sharedClassId}
              onChange={(e) => handleClassChange(index, e.target.value)}
            >
              <option value="" disabled>
                Share a class
              </option>
              {uniqueClasses.map((cls) => (
                <option key={cls.id} value={cls.id}>
                  {cls.name}
                </option>
              ))}
            </Form.Control>
            {colleagueEntries.length > 1 && (
              <Button
                variant="outline-danger"
                className="remove-btn"
                onClick={() => removeColleague(index)}
              >
                X
              </Button>
            )}
          </div>
        ))
      )}
      <button className="light-blue-button" onClick={addColleague}>
        Add Colleague
      </button>
    </>
  );
}

export default AdditionalColleaguesFunction;
