import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import {
  editClass,
  updateClassOwnerId,
  createClassOwnershipHistory,
  shareClass,
} from "../services/APIClient";

import "./SaveClassDetails.css";

function SaveClassDetailsSpecial(props) {
  const {
    classDetails,
    teachers,
    newClassName,
    newGrade,
    newClassTeacherId,
    schoolid,
    isNameUpdated, // Add isNameUpdated to props
    teacherid, // Add teacherid for ownership history
    classOwnerId, // Add classOwnerId for ownership history
    selectedReason, // Add selectedReason for ownership history
  } = props;
  const classid = classDetails.id;
  const navigate = useNavigate();

  // State to control the modal visibility
  const [showModal, setShowModal] = useState(false);

  // Prepare class ownership history data
  const classOwnershipHistoryData = {
    teacherId: teacherid,
    oldClassOwner: classOwnerId,
    newClassOwner: newClassTeacherId,
    reasonForChangeId: selectedReason?.id, // Use selected reason if provided
    classownerchangereason: selectedReason?.reason,
    dateChanged: new Date().toISOString(),
  };

  const handleClassEdit = async (e) => {
    e.preventDefault();

    // If the name isn't updated, show the modal
    if (!isNameUpdated) {
      setShowModal(true);
      return;
    }

    try {
      // Update class details
      await editClass(classid, newClassName, newGrade);

      // Update class owner ID if a new teacher is selected
      if (newClassTeacherId) {
        await updateClassOwnerId(classid, newClassTeacherId);
        await shareClass(classid, newClassTeacherId); // Keep this unique functionality from SaveClassDetailsSpecial
        await createClassOwnershipHistory(classOwnershipHistoryData); // New functionality
      }

      // Redirect after saving, forcing the component to re-fetch data
      navigate(`/class/detail/`, {
        state: {
          classDetails: { classDetails },
          teachers: { teachers },
          name: { newClassName },
          gradeLevel: { newGrade },
          schoolid: { schoolid },
          forceRefreshKey: Date.now(), // Unique timestamp for re-fetching
        },
      });
    } catch (error) {
      console.error("Error saving class details:", error);
    }
  };

  return (
    <>
      <button
        onClick={handleClassEdit}
        className="green-button"
        style={{
          border: "1px solid #16971b",
        }}
      >
        Save
      </button>

      {/* Modal to show when the class name hasn't been updated */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Update Required</Modal.Title>
        </Modal.Header>
        <Modal.Body>Please update the class name before saving.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SaveClassDetailsSpecial;
