import React, { useState } from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels"; // Import the data labels plugin
import { useNavigate } from "react-router";

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels); // Register the plugin

const StudentPerformancePieChart = ({
  assessmentResultsByStudent,
  thresholds,
  classDetails,
  classAssessments,
  gradeLevel,
  teachers,
  assessment,
  toggleScoreType,
  schoolid,
  teacherid,
  fromSencoResults,
}) => {
  const [selectedTask, setSelectedTask] = useState(null);
  const [studentsBelowThreshold, setStudentsBelowThreshold] = useState([]);
  const navigate = useNavigate(); // Hook for navigation

  let fullTaskNameArray = [];
  function taskNameRetriever() {
    for (let i = 0; i < assessmentResultsByStudent.length; i++) {
      let student = assessmentResultsByStudent[i];
      let studentTasks = student?.tasks;

      if (studentTasks) {
        studentTasks.sort((a, b) => a.order - b.order);
      }

      for (let j = 0; j < studentTasks?.length; j++) {
        let taskNames = studentTasks[j].name;
        fullTaskNameArray.push(taskNames);
      }
    }
  }
  taskNameRetriever();

  let taskNameArray = [...new Set(fullTaskNameArray)];

  const toIndividualStudentResults = (student) => {
    navigate("/student/results", {
      state: {
        student: { student },
        classDetails: { classDetails },
        classAssessments: { classAssessments },
        gradeLevel: { gradeLevel },
        teachers: { teachers },
        assessment: { assessment },
        taskNameArray: { taskNameArray },
        assessmentResultsByStudent: { assessmentResultsByStudent },
        toggleScoreType: { toggleScoreType },
        schoolid: { schoolid },
        teacherid: { teacherid },
        fromSencoResults: { fromSencoResults },
      },
    });
  };

  const getUniqueTasks = () => {
    const tasks = new Set();
    assessmentResultsByStudent.forEach((student) => {
      student.tasks.forEach((task) => tasks.add(task.name));
    });
    return Array.from(tasks);
  };

  const availableTasks = getUniqueTasks();

  const getTaskData = () => {
    const labels = [];
    const data = [];
    const backgroundColors = [];
    const borderColors = [];

    availableTasks.forEach((taskName, index) => {
      const threshold = thresholds[taskName]?.student;

      if (threshold !== undefined) {
        const studentsBelowThresholdForTask =
          assessmentResultsByStudent.flatMap((student) =>
            student.tasks
              .filter(
                (task) => task.name === taskName && task.score * 100 < threshold
              )
              .map((task) => student.student)
          );

        const percentageBelowThreshold =
          (studentsBelowThresholdForTask.length /
            assessmentResultsByStudent.length) *
          100;

        labels.push(taskName);
        data.push(
          percentageBelowThreshold > 0 ? percentageBelowThreshold : null
        );
        backgroundColors.push(`hsl(${index * 60}, 70%, 70%)`);
        borderColors.push(`hsl(${index * 60}, 70%, 50%)`);
      }
    });

    return {
      labels,
      datasets: [
        {
          data: data,
          backgroundColor: backgroundColors,
          borderColor: borderColors,
          borderWidth: 1,
        },
      ],
    };
  };

  const handleSliceClick = (event, elements) => {
    if (elements.length > 0) {
      const element = elements[0];
      const chart = element.element.$context.chart;

      if (chart) {
        const index = element.index;
        const taskName = chart.data.labels[index];

        const threshold = thresholds[taskName]?.student;

        if (threshold !== undefined) {
          const studentsBelowThresholdForTask =
            assessmentResultsByStudent.flatMap((student) =>
              student.tasks
                .filter(
                  (task) =>
                    task.name === taskName && task.score * 100 < threshold
                )
                .map((task) => student.student)
            );

          setSelectedTask(taskName);
          setStudentsBelowThreshold(studentsBelowThresholdForTask);
        }
      } else {
        console.error("Chart object not found.");
      }
    }
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        labels: {
          filter: (legendItem) => {
            return true; // Show all tasks in the legend, even if they have no data
          },
        },
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            return `${context.label}: ${
              context.raw ? context.raw.toFixed(2) + "%" : "No data"
            }`;
          },
        },
        backgroundColor: "rgba(0, 0, 0, 0.7)", // Background color of the tooltip
        bodyColor: "white", // Text color
        titleColor: "white", // Title text color
        borderColor: "white", // Border color of the tooltip
        borderWidth: 1, // Border width
        font: {
          size: 12, // Font size for the tooltip text
        },
      },
      datalabels: {
        color: "white", // Text color for data labels inside slices
        font: {
          size: 12, // Font size
          weight: "bold", // Font weight
        },
        formatter: (value) => {
          return value ? value.toFixed(2) + "%" : ""; // Display percentage with 2 decimal places
        },
        anchor: "center",
        align: "center",
        padding: 5, // Padding around text
      },
    },
    onClick: (event, elements) => handleSliceClick(event, elements),
  };

  const data = getTaskData();

  const textboxStyle = {
    backgroundColor: "white",
    padding: "10px",
    borderRadius: "5px",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
    fontSize: "12px",
    maxWidth: "250px",
    textAlign: "left",
    marginBottom: "20px",
  };

  const recommendationsStyle = {
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    fontSize: "16px",
    // fontWeight: "bold",
    maxWidth: "600px",
    textAlign: "center",
    margin: "20px auto", // Center horizontally with margin
    lineHeight: "1.5", // Line height for readability
  };

  const groupSectionStyle = {
    marginTop: "20px",
    padding: "20px",
    // border: "1px solid #ccc",
    fontSize: "16px",
    borderRadius: "8px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    lineHeight: "1.5",
    borderRadius: "8px",
    maxWidth: "600px",
    margin: "20px auto",
    textAlign: "center", // Center the text
    display: "flex", // Use flexbox
    flexDirection: "column", // Arrange content vertically
    alignItems: "center", // Center content horizontally
  };

  const renderStudentGroups = () => {
    const groups = {
      "Extension Group (any child who was in the GREEN category 80-100% average raw score)":
        [],
      "Average Group (any child who was in the WHITE category 40-80% average raw score)":
        [],
      "Below Average Group (any child who was in the ORANGE category 20-40% average raw score)":
        [],
      "Intervention Group (any child who was in the RED category 0-20% average raw score)":
        [],
    };

    assessmentResultsByStudent.forEach((studentAssessment) => {
      const student = studentAssessment.student;
      const scores = studentAssessment.tasks.map((task) => task.score);
      const averageScore = scores.reduce((a, b) => a + b, 0) / scores.length;

      if (averageScore >= 0.8) {
        groups[
          "Extension Group (any child who was in the GREEN category 80-100% average raw score)"
        ].push(student);
      } else if (averageScore >= 0.4) {
        groups[
          "Average Group (any child who was in the WHITE category 40-80% average raw score)"
        ].push(student);
      } else if (averageScore >= 0.2) {
        groups[
          "Below Average Group (any child who was in the ORANGE category 20-40% average raw score)"
        ].push(student);
      } else {
        groups[
          "Intervention Group (any child who was in the RED category 0-20% average raw score)"
        ].push(student);
      }
    });

    return Object.keys(groups).map((group) => (
      <div key={group} style={{ marginBottom: "15px" }}>
        <h5>{group}</h5>
        <ul>
          {groups[group].length > 0 ? (
            groups[group].map((student, index) => (
              <li key={index} style={{ listStyleType: "none" }}>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    toIndividualStudentResults(student);
                  }}
                  style={{ color: "blue", cursor: "pointer" }}
                >
                  {student.name}
                </a>
              </li>
            ))
          ) : (
            <li style={{ listStyleType: "none" }}>
              No students in this group.
            </li>
          )}
        </ul>
      </div>
    ));
  };

  return (
    <>
      <div style={{ display: "flex" }}>
        <div style={{ width: "60%" }}>
          <h5 style={{ textAlign: "center" }}>
            Students Flagged for Low Performance by Competency
          </h5>
          <Pie data={data} options={options} />
        </div>
        <hr></hr>

        <div
          style={{
            width: "40%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            paddingLeft: "20px",
          }}
        >
          <div style={textboxStyle}>
            This graph shows children who have not reached the minimum threshold
            in a particular subtest and may need extra support in the area. It
            is divided according to subtest areas. If you click on an individual
            portion of the chart, you can see the names of the children who
            struggled with that subtest.
          </div>
          <hr></hr>
          <div
            style={{
              width: "100%",
              padding: "20px",
              border: "1px solid #ccc",
              borderRadius: "8px",
            }}
          >
            {selectedTask ? (
              <>
                <p>Students who may require extra help with {selectedTask}:</p>
                <ul>
                  {studentsBelowThreshold.length > 0 ? (
                    studentsBelowThreshold.map((student, index) => (
                      <li key={index} style={{ listStyleType: "none" }}>
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            toIndividualStudentResults(student);
                          }}
                          style={{ color: "blue", cursor: "pointer" }}
                        >
                          {student.name}
                        </a>
                      </li>
                    ))
                  ) : (
                    <li style={{ listStyleType: "none" }}>
                      No students below the threshold for this task.
                    </li>
                  )}
                </ul>
              </>
            ) : (
              <p>Select a task to see students who need help.</p>
            )}
          </div>
        </div>
      </div>
      <hr></hr>
      <div style={recommendationsStyle}>
        <p>
          <strong>Recommendations for Action</strong>
        </p>
        <p>
          Based on the children identified above, the following may improve
          overall performance:
        </p>
        <ul style={{ textAlign: "left" }}>
          <li>
            <strong>Group Teaching:</strong> For subtests where a few children
            struggled (e.g. Letter Name and Phoneme Blending), consider
            organizing small group teaching where these children can work
            together to improve in these areas.
          </li>
          <li>
            <strong>Individual Support:</strong> For subtests with fewer
            children identified, individual support may help address specific
            needs.
          </li>
        </ul>
      </div>
      <hr></hr>
      <div style={groupSectionStyle}>
        <h2>Student Groups Based on Average Score</h2>
        {renderStudentGroups()}
      </div>
    </>
  );
};

export default StudentPerformancePieChart;
