import React, { useState } from "react";

const AllStudentsIndividualSearch = ({
  allStudentsResults, // Data for all students from all classes
  selectedChild,
  setSelectedChild,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);
  const [resultsVisible, setResultsVisible] = useState(false); // Initially set to false

  const handleSearchChange = (event) => {
    const query = event.target?.value?.toLowerCase() || "";
    setSearchTerm(query);

    if (query) {
      // Only filter and show results if there's a search term
      const results = allStudentsResults.filter((result) =>
        result.student?.name.toLowerCase().includes(query)
      );

      setFilteredResults(results);
      setResultsVisible(true); // Show results when typing in the search bar
    } else {
      setFilteredResults([]); // Clear results when the search term is empty
      setResultsVisible(false); // Hide results if the search term is empty
    }
  };

  const handleStudentClick = (result) => {
    setSelectedChild(result);
    setSearchTerm(result.student?.name || ""); // Display selected student's name in search bar
    setResultsVisible(false); // Hide results when a student is selected
  };

  return (
    <div style={{ width: "80%", margin: "auto", padding: "20px" }}>
      <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
        Search All Students to See Their Results
      </h2>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearchChange}
          placeholder="Search by student name"
          style={{
            width: "100%",
            maxWidth: "500px",
            padding: "10px",
            fontSize: "16px",
            textAlign: "center",
          }}
        />
      </div>
      {resultsVisible && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div style={{ width: "100%", maxWidth: "500px" }}>
            {filteredResults.length > 0 ? (
              <ul style={{ listStyleType: "none", padding: 0 }}>
                {filteredResults.map((result, index) => (
                  <li
                    key={index}
                    style={{
                      padding: "10px",
                      borderBottom: "1px solid #ddd",
                      cursor: "pointer",
                      backgroundColor:
                        selectedChild &&
                        result.student?.id === selectedChild.student?.id
                          ? "#f0f0f0"
                          : "white",
                      border:
                        selectedChild &&
                        result.student?.id === selectedChild.student?.id
                          ? "2px solid #007bff"
                          : "none",
                    }}
                    onClick={() => handleStudentClick(result)}
                  >
                    <strong>{result.student?.name || "Unknown"}</strong>
                  </li>
                ))}
              </ul>
            ) : (
              <p style={{ textAlign: "center" }}>No results found</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AllStudentsIndividualSearch;
