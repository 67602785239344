import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Card from "react-bootstrap/Card";
import HeadingBanner from "../components/HeadingBanner";
import LeftPanel from "../components/LeftPanel";
import AdditionalColleaguesButtonMD from "../components/AdditionalColleaguesButtonMD";
import AdditionalColleaguesButtonMD2 from "../components/AdditionalColleaguesButtonMD2";
import ReferAFriendButton from "../components/ReferFriendsButton";
import IntroVideoButton from "../components/IntroVideoButton";
import AlpacaInfoGesture from "../components/AlpacaInfoGesture";
import { Modal, Button } from "react-bootstrap";
import {
  whoami,
  getClasses,
  getTeachers,
  getSchools,
  shareClass,
  updateClassOwnerId,
  postLastLogin,
  getLastLogin,
} from "../services/APIClient";
import { Container, Row, Col } from "react-bootstrap";
import "../components/ClassCard.css";
import "../components/MasterDashCards.css";
import OrientationPrompt from "../components/OrientationPrompt";

function MasterDashboard() {
  const [schoolid, setSchoolid] = useState(null);
  const [teacherid, setTeacherid] = useState(null);
  const [name, setName] = useState("");
  const [teachers, setTeachers] = useState(null);
  const [firstname, setFirstname] = useState(null);
  const [speechText, setSpeechText] = useState(null);
  const [classes, setClasses] = useState([]);
  const [classid, setClassid] = useState([]);
  const [rollNumber, setRollNumber] = useState(null);
  const [teacherEmail, setTeacherEmail] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [isPortrait, setIsPortrait] = useState(
    window.matchMedia("(orientation: portrait)").matches
  );
  const [sharedClassId, setSharedClassId] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    window.HubSpotConversations?.widget?.refresh();
  }, []);

  useEffect(() => {
    const handleOrientationChange = () => {
      setIsPortrait(window.innerWidth < window.innerHeight);
    };

    window.addEventListener("resize", handleOrientationChange);

    return () => {
      window.removeEventListener("resize", handleOrientationChange);
    };
  }, []);

  // useEffect(() => {
  //   const savedSharedClassId = localStorage.getItem("sharedClassId");
  //   if (savedSharedClassId) {
  //     setSharedClassId(savedSharedClassId);
  //     console.log("Shared Class ID from local storage:", savedSharedClassId); // Log the shared class ID
  //   }

  //   whoami().then((response) => {
  //     // console.log(response)
  //     setSchoolid(response.data.stakeholder.schoolid);
  //     setTeacherid(response.data.stakeholder.id);
  //     setName(response.data.stakeholder.name);
  //     setTeacherEmail(response.data.email);
  //   });
  // }, []);

  useEffect(() => {
    const savedSharedClassId = localStorage.getItem("sharedClassId");
    const loggedInAlready = localStorage.getItem("loggedInAlready");
    const newUserSignup = localStorage.getItem("newUserSignup");

    if (savedSharedClassId) {
      setSharedClassId(savedSharedClassId);
      console.log("Shared Class ID from local storage:", savedSharedClassId);
    }

    whoami().then((response) => {
      setSchoolid(response.data.stakeholder.schoolid);
      setTeacherid(response.data.stakeholder.id);
      setName(response.data.stakeholder.name);
      setTeacherEmail(response.data.email);

      if (loggedInAlready === "true") {
        console.log(
          "Profile already updated or new user just signed up, skipping login check."
        );
        return; // Want to only capture initial logins and ignore new sign ups
      }

      getLastLogin(response.data.stakeholder.id)
        .then((lastLoginData) => {
          const lastLogin = lastLoginData
            ? new Date(lastLoginData.lastloggedin)
            : null;
          const thresholdDate = new Date("2024-09-01T00:00:00Z");

          // Log the last login before redirecting to profile
          postLastLogin(response.data.stakeholder.id)
            .then(() => {
              console.log("Last login logged successfully");
              localStorage.setItem("loggedInAlready", "true");

              if (newUserSignup === "true") {
                console.log(
                  "Profile already updated or new user just signed up, skipping login check."
                );
                return; // Want to only capture initial logins and ignore new sign ups
              }

              // Check if there's no last login or it's before the threshold
              if (!lastLogin || lastLogin < thresholdDate) {
                console.log(
                  "Redirecting to profile due to first login or outdated last login."
                );
                navigate(`/profile`, {
                  state: {
                    teacherid: response.data.stakeholder.id,
                    profilePageRedirect: true,
                  },
                });
              }
            })
            .catch((error) => {
              console.error("Error logging last login:", error);
            });
        })
        .catch((error) => {
          console.error("Error fetching last login:", error);
        });
    });
  }, []);

  useEffect(() => {
    if (schoolid) {
      Promise.all([getTeachers(schoolid), getClasses(), getSchools()])
        .then(([teachersResponse, classesResponse, schoolsResponse]) => {
          setTeachers(teachersResponse);
          setClasses(classesResponse);
          setClassid(classesResponse.id);

          const matchingSchool = schoolsResponse.data.find(
            (school) => school.id === schoolid
          );

          if (matchingSchool) {
            const rollNumber = matchingSchool.rollnumber;
            setRollNumber(rollNumber);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          setLoading(false); // Set loading to false after data is fetched
        });
    }
  }, [schoolid]);

  useEffect(() => {
    if (name) {
      let nameArray = name.split(" ");
      setFirstname(nameArray[0]);
    }
  }, [name, schoolid]);

  useEffect(() => {
    setSpeechText(
      `Hello ${firstname}, let's start by setting up a class. Click 'Admin' to begin or use the button above my head to explore the Dashboard.`
    );
  }, [firstname]);

  useEffect(() => {
    window.HubSpotConversations?.widget?.refresh();
  }, []);

  useEffect(() => {
    if (sharedClassId && teacherid) {
      shareClass(sharedClassId, teacherid, true) // Pass the special condition flag
        .then((data) => {
          // Save sharedClassId as classid
          setClassid(sharedClassId);

          // Now call updateClassOwnerId to update the class owner
          return updateClassOwnerId(sharedClassId, teacherid); // Update the class owner ID
        })
        .then((updatedClass) => {
          console.log("Class owner ID updated successfully:", updatedClass);

          // Remove sharedClassId from local storage after successful operation
          localStorage.removeItem("sharedClassId");
        })
        .catch((error) => {
          console.error("Error sharing or updating class owner ID:", error);
        });
    }
  }, [sharedClassId, teacherid]);

  useEffect(() => {
    const fromCreateClass = localStorage.getItem("fromCreateClass");
    if (fromCreateClass === "true" && sharedClassId && teacherid) {
      updateClassOwnerId(sharedClassId, teacherid) // Update the class owner ID
        .then((updatedClass) => {
          console.log("Class owner ID updated successfully:", updatedClass);
          // Remove 'fromCreateClass' from local storage
          localStorage.removeItem("fromCreateClass");
        })
        .catch((error) => {
          console.error("Error updating class owner ID:", error);
        });
    }
  }, [sharedClassId, teacherid]);

  return (
    <div>
      <Container fluid className="vh-100">
        <HeadingBanner
          name={`Welcome ${name}!`}
          isLoggedIn={true}
          cloud={true}
          teacherid={teacherid}
        />
        <Row className="h-100">
          <LeftPanel />
          <Col sm={{ span: 10, offset: 2 }} className="blue-background">
            {loading ? (
              <div className="spinner-container">
                <div className="big-ball-spinner"></div>
              </div>
            ) : (
              <>
                {classes.length === 0 ? (
                  <Row>
                    <IntroVideoButton />
                    <Col className="col-3"></Col>
                    {/* <br></br> */}
                    <AdditionalColleaguesButtonMD2
                      rollNumber={rollNumber}
                      teacherEmail={teacherEmail}
                    />
                  </Row>
                ) : (
                  <Row className="invite-colleagues-row-position">
                    <div className="additional-colleague-button ">
                      <div className="assessment-reminder-content hide-assessment-banner">
                        <Link
                          className="ass-heading-link "
                          to={`/assessmentsdashboard`}
                          state={{
                            classDetails: { classes },
                            teachers: { teachers },
                            name: { name },
                          }}
                        >
                          <img
                            src="../assets/imgs/spring-icon.png"
                            className="assessment-reminder-card-img"
                            alt="Colleague Icon"
                          />
                          <p className="ass-heading-card-text">
                            Midpoint Assessment Season is Here! <br /> Time to
                            Assign and Shine!
                          </p>
                          <img
                            src="../assets/imgs/spring-icon.png"
                            className="assessment-reminder-card-img"
                            alt="Colleague Icon"
                          />
                        </Link>
                      </div>
                    </div>
                    <Col>
                      <AdditionalColleaguesButtonMD
                        rollNumber={rollNumber}
                        teacherEmail={teacherEmail}
                      />
                    </Col>
                    <Col>
                      <ReferAFriendButton
                        rollNumber={rollNumber}
                        teacherEmail={teacherEmail}
                        teacherid={teacherid}
                        name={name}
                      />
                    </Col>
                  </Row>
                )}
                <Row className="masterdash-row-position">
                  <Col className="col-3">
                    <Card className="masterdash-card-detail">
                      <Link
                        className="link"
                        to={`/admindashboard`}
                        state={{
                          classDetails: { classes },
                          teachers: { teachers },
                          name: { name },
                          rollNumber: { rollNumber },
                        }}
                      >
                        <img
                          src="./assets/imgs/admin-icon.png"
                          className="master-class-card-img"
                          alt="Admin Icon"
                        ></img>
                        <p className="class-card-text">Admin</p>
                      </Link>
                    </Card>
                  </Col>
                  {classes.length === 0 ? (
                    <AlpacaInfoGesture speechText={speechText} />
                  ) : (
                    <>
                      <Col className="col-3">
                        <Card className="masterdash-card-detail  assign-assessment-card">
                          <Link
                            className="link"
                            to={`/assessmentsdashboard`}
                            state={{
                              classDetails: { classes },
                              teachers: { teachers },
                              name: { name },
                            }}
                          >
                            <img
                              src="./assets/imgs/assignassessments-icon.png"
                              className="master-class-card-img"
                              alt="Assign Assessments Icon"
                            ></img>
                            <p className="class-card-text">Assign Screener</p>
                          </Link>
                        </Card>
                      </Col>

                      <Col className="col-3">
                        <Card className="masterdash-card-detail">
                          <Link
                            className="link"
                            to={`/qrdashboard`}
                            state={{
                              classDetails: { classes },
                              teachers: { teachers },
                              name: { name },
                            }}
                          >
                            <img
                              src="./assets/imgs/qrs-icon.png"
                              className="master-class-card-img"
                              alt="Student QRs Icon"
                            ></img>
                            <p className="class-card-text">Student QRs</p>
                          </Link>
                        </Card>
                      </Col>
                      <Col className="col-3">
                        <Card className="masterdash-card-detail">
                          <Link
                            className="link"
                            to={`/resultsdashboard`}
                            state={{
                              classDetails: { classes },
                              teachers: { teachers },
                              name: { name },
                            }}
                          >
                            <img
                              src="./assets/imgs/viewresults-icon.png"
                              className="master-class-card-img"
                              alt="View Results Icon"
                            ></img>
                            <p className="class-card-text">View Results</p>
                          </Link>
                        </Card>
                      </Col>
                    </>
                  )}
                </Row>
              </>
            )}
          </Col>
        </Row>
      </Container>
      {isPortrait && <OrientationPrompt />}
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        dialogClassName="fun-vibrant-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Assessment Window Closed</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          The current assessment window is now closed. We will be reopening the
          ALPACA assessment for the <strong>End of Year</strong> assessment
          window on the <strong>7th May</strong>.
        </Modal.Body>
        <Modal.Body>Thank you for your understanding.</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default MasterDashboard;
