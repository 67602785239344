import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";

import LeftPanel from "../components/LeftPanel";
import HeadingBanner from "../components/HeadingBanner";
import StudentProfileForm from "../components/StudentProfileForm";
import OrientationPrompt from "../components/OrientationPrompt";

function StudentSettings(props) {
  const location = useLocation();

  let name = "Student Settings";
  let teacherid = location.state.classDetails.teacherId;
  let schoolid = location.state.schoolid;
  let student = location.state.student;
  let classDetails = location.state.classDetails;
  let teacherRoles = location.state.teacherRoles;
  const [isPortrait, setIsPortrait] = useState(
    window.matchMedia("(orientation: portrait)").matches
  );

  useEffect(() => {
    const handleOrientationChange = () => {
      setIsPortrait(window.innerWidth < window.innerHeight);
    };

    window.addEventListener("resize", handleOrientationChange);

    return () => {
      window.removeEventListener("resize", handleOrientationChange);
    };
  }, []);

  return (
    <Container fluid className="vh-100">
      <HeadingBanner
        isLoggedIn={true}
        cloud={true}
        name={name}
        teacherid={teacherid}
      />
      <Row className="h-100">
        <LeftPanel />
        <Col sm={{ span: 10, offset: 2 }} className="blue-background">
          <div className="student-settings-container">
            <Container className="student-settings-container-shape">
              <Row className="page-description-bar">
                <Col className="col-8">
                  <h2>Edit Student Details</h2>
                  <h6></h6>
                </Col>
                <Col className="col-4">
                  <Row>
                    <Col className="col-6"></Col>
                    <Col className="col-6"></Col>
                  </Row>
                </Col>
              </Row>

              <hr />
              <StudentProfileForm
                student={student}
                schoolid={schoolid}
                classDetails={classDetails}
                teacherRoles={teacherRoles}
              />
            </Container>
          </div>
        </Col>
      </Row>
      {isPortrait && <OrientationPrompt />}
    </Container>
  );
}

export default StudentSettings;
