import React, { useEffect } from "react";
import { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { jwtDecode } from "jwt-decode";

import App from "./pages/App";
import ClassDetail from "./pages/ClassDetail";
import CreateClass from "./pages/CreateClass";
import CreateSETClass from "./pages/CreateSETClass";
import CreateTrialClass from "./pages/CreateTrialClass";
import CreateSpecialEdClass from "./pages/CreateSpecialEdClass";
import ShareClass from "./pages/ShareClass";
import AddStudents from "./pages/AddStudents";
import ClassSettings from "./pages/ClassSettings";
import ClassSettingsSpecial from "./pages/ClassSettingsSpecial";
import StudentsResults from "./pages/StudentsResults";
import StudentDetails from "./pages/StudentDetails";
import StudentSettings from "./pages/StudentSettings";
import IndividualStudentResults from "./pages/IndividualStudentResults";
import AssignAssessment from "./pages/AssignAssessment";
import TeacherProfile from "./pages/TeacherProfile";
import Results from "./pages/Results";

import MasterDashboard from "./pages/MasterDashboard";
import AdminDashboard from "./pages/AdminDashboard";
import AssessmentsDashboard from "./pages/AssessmentsDashboard";
import ClassAdminDashboard from "./pages/ClassAdminDashboard";
import QRDashboard from "./pages/QRDashboard";
import ResultsDashboard from "./pages/ResultsDashboard";
import AssessmentDetail from "./pages/AssessmentDetail";
import ResultDetail from "./pages/ResultDetail";
import Sandbox from "./pages/Sandbox";
import SuperSecretInternalDash from "./pages/SuperSecretInternalDash";
import MyColleagues from "./pages/MyColleagues";
import InviteColleagues from "./pages/InviteColleagues";
import ReferFriends from "./pages/ReferFriends";
import Reports from "./pages/Reports";
import SencoReports from "./pages/SencoReports";
import JiSiReports from "./pages/JiSiReports";
import RetestTaskDashboard from "./pages/RetestTaskDashboard";
import AssignTask from "./pages/AssignTask";
import RetestTaskDetail from "./pages/RetestTaskDetail";
import AllStudentResults from "./pages/AllStudentResults";
import AllStudentReportCards from "./pages/AllStudentReportCards";
import SencoResultDetail from "./pages/SencoResultDetail";
import SencoStudentsResults from "./pages/SencoStudentResults";

import "./fonts/Adia_Demo.ttf";

import "./index.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import SignUp from "./pages/SignUp";
import Recovery from "./pages/Recovery";
import Recommendations from "./pages/Recommendations";

const container = document.getElementById("root");
const root = createRoot(container);

function ProtectedRoute({ element }) {
  const token = localStorage.getItem("token");
  console.log("Checking token...");

  if (!token) {
    console.log("No token found, redirecting to home page.");
    return <Navigate to="/" replace />;
  }

  try {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000;

    if (decodedToken.exp < currentTime) {
      console.log(
        "Token expired, removing token and redirecting to home page."
      );
      localStorage.removeItem("token");
      return <Navigate to="/" replace />;
    }

    console.log("Token is valid, rendering element.");
  } catch (e) {
    console.error(
      "Token decoding failed or invalid token, redirecting to home page."
    );
    return <Navigate to="/" replace />;
  }

  return element;
}

function RootComponent() {
  useEffect(() => {
    window.HubSpotConversations?.widget?.refresh();
    console.log("HubSpot widget refreshed.");
  }, []);

  return (
    <BrowserRouter forceRefresh={true}>
      <Suspense
        fallback={
          <div className="spinner-container">
            <div className="big-ball-spinner"></div>
          </div>
        }
      >
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/recovery" element={<Recovery />} />
          <Route
            path="/sandbox"
            element={<ProtectedRoute element={<Sandbox />} />}
          />
          <Route
            path="/masterdashboard"
            element={<ProtectedRoute element={<MasterDashboard />} />}
          />
          <Route
            path="/admindashboard"
            element={<ProtectedRoute element={<AdminDashboard />} />}
          />
          <Route
            path="/assessmentsdashboard"
            element={<ProtectedRoute element={<AssessmentsDashboard />} />}
          />
          <Route
            path="/classadmindashboard"
            element={<ProtectedRoute element={<ClassAdminDashboard />} />}
          />
          <Route
            path="/qrdashboard"
            element={<ProtectedRoute element={<QRDashboard />} />}
          />
          <Route
            path="/resultsdashboard"
            element={<ProtectedRoute element={<ResultsDashboard />} />}
          />
          <Route
            path="/profile"
            element={<ProtectedRoute element={<TeacherProfile />} />}
          />
          <Route
            path="/createclass"
            element={<ProtectedRoute element={<CreateClass />} />}
          />
          <Route
            path="/createspecialedclass"
            element={<ProtectedRoute element={<CreateSpecialEdClass />} />}
          />
          <Route
            path="/createtrialclass"
            element={<ProtectedRoute element={<CreateTrialClass />} />}
          />
          <Route
            path="/createsetclass"
            element={<ProtectedRoute element={<CreateSETClass />} />}
          />
          <Route
            path="/class/detail"
            element={<ProtectedRoute element={<ClassDetail />} />}
          />
          <Route
            path="/assessment/detail"
            element={<ProtectedRoute element={<AssessmentDetail />} />}
          />
          <Route
            path="/results/detail"
            element={<ProtectedRoute element={<ResultDetail />} />}
          />
          <Route
            path="/sencoresults/detail"
            element={<ProtectedRoute element={<SencoResultDetail />} />}
          />
          <Route
            path="/class/share"
            element={<ProtectedRoute element={<ShareClass />} />}
          />
          <Route
            path="/addstudents"
            element={<ProtectedRoute element={<AddStudents />} />}
          />
          <Route
            path="/class/settings"
            element={<ProtectedRoute element={<ClassSettings />} />}
          />
          <Route
            path="/class/settings/special"
            element={<ProtectedRoute element={<ClassSettingsSpecial />} />}
          />
          <Route
            path="/studentsresults"
            element={<ProtectedRoute element={<StudentsResults />} />}
          />
          <Route
            path="/senco/studentsresults"
            element={<ProtectedRoute element={<SencoStudentsResults />} />}
          />
          <Route
            path="/student/details"
            element={<ProtectedRoute element={<StudentDetails />} />}
          />

          <Route
            path="/student/settings"
            element={<ProtectedRoute element={<StudentSettings />} />}
          />
          <Route
            path="/student/results"
            element={<ProtectedRoute element={<IndividualStudentResults />} />}
          />

          <Route
            path="/allstudents/results"
            element={<ProtectedRoute element={<AllStudentResults />} />}
          />
          <Route
            path="/allstudents/reportcards"
            element={<ProtectedRoute element={<AllStudentReportCards />} />}
          />
          <Route
            path="/assignassessment"
            element={<ProtectedRoute element={<AssignAssessment />} />}
          />
          <Route
            path="/recommendations"
            element={<ProtectedRoute element={<Recommendations />} />}
          />
          <Route
            path="/results"
            element={<ProtectedRoute element={<Results />} />}
          />
          <Route
            path="/supersecretinternaldash"
            element={<ProtectedRoute element={<SuperSecretInternalDash />} />}
          />
          <Route
            path="/mycolleagues"
            element={<ProtectedRoute element={<MyColleagues />} />}
          />
          <Route
            path="/invitecolleagues"
            element={<ProtectedRoute element={<InviteColleagues />} />}
          />
          <Route
            path="/referfriends"
            element={<ProtectedRoute element={<ReferFriends />} />}
          />
          <Route
            path="/reports"
            element={<ProtectedRoute element={<Reports />} />}
          />
          <Route
            path="/sencoreports"
            element={<ProtectedRoute element={<SencoReports />} />}
          />
          <Route
            path="/jisireports"
            element={<ProtectedRoute element={<JiSiReports />} />}
          />
          <Route
            path="/retesttaskdashboard"
            element={<ProtectedRoute element={<RetestTaskDashboard />} />}
          />
          <Route
            path="/assigntask"
            element={<ProtectedRoute element={<AssignTask />} />}
          />
          <Route
            path="/retesttaskdetail"
            element={<ProtectedRoute element={<RetestTaskDetail />} />}
          />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

root.render(<RootComponent />);

reportWebVitals();
