import React, { useState, useEffect } from "react";
import { withRouter } from "../utils/withRouter.js";
import { useNavigate } from "react-router";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Form from "react-bootstrap/Form";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  createClass,
  getCountryGrades,
  getSchools,
  getTeachers,
  whoami,
} from "../services/APIClient";
import SimpleNavigationButton from "./SimpleNavigationButton";
import ConfirmClassOwnerModal from "./ConfirmClassOwnerModal";

function CreateClassForm(props) {
  const [selectedGradeLevel, setSelectedGradeLevel] = useState("");
  const [classTitle, setClassTitle] = useState("");
  const [gradeLevelOptions, setGradeLevelOptions] = useState([]);
  const [teacherOptions, setTeacherOptions] = useState([]);
  const [classTitleError, setClassTitleError] = useState("");
  const [gradeError, setGradeError] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [classOwner, setClassOwner] = useState(null);

  const [countryId, setCountryId] = useState(null);
  const [isInviteColleague, setIsInviteColleague] = useState(false);

  const teacherId = props.teacherid;
  const schoolid = props.router.location.state.schoolid.schoolid;
  let teacherRoles = props.teacherRoles;

  const [rollNumber, setRollNumber] = useState("");

  const [teacherEmail, setTeacherEmail] = useState("");
  const [name, setName] = useState("");
  const navigate = useNavigate();

  let teachers = teacherOptions;
  let fromCreateClass = true;

  useEffect(() => {
    console.log(isInviteColleague);
  }, [isInviteColleague]);

  useEffect(() => {
    whoami().then((response) => {
      console.log(response, "WHOAMI");
      setTeacherEmail(response.data.email);

      setName(response.data.stakeholder.name);
    });
    getSchools().then((response) => {
      console.log(response);
      setCountryId(response.data.countryid);
      setRollNumber(response.data.rollnumber);
    });
  }, []);

  const defaultGradeLevel = () => {
    const lowerCasedRoles = teacherRoles.map((role) => role.toLowerCase());

    if (
      lowerCasedRoles.some((role) => role.includes("set")) ||
      lowerCasedRoles.some((role) => role.includes("senco"))
    ) {
      return "";
    }

    if (lowerCasedRoles.some((role) => role.includes("junior infant"))) {
      return "Junior Infants";
    }

    if (lowerCasedRoles.some((role) => role.includes("senior infant"))) {
      return "Senior Infants";
    }

    return "";
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const schoolsResponse = await getSchools();
        const matchingSchool = schoolsResponse.data.find(
          (school) => school.id === schoolid
        );

        if (matchingSchool) {
          const countryid = matchingSchool.countryid;
          setCountryId(countryid);
          const gradesResponse = await getCountryGrades(countryid);
          const { grade1, grade2 } = gradesResponse;
          setGradeLevelOptions([grade1, grade2]);

          const teachersResponse = await getTeachers(schoolid);
          setTeacherOptions(teachersResponse);

          const defaultGrade = defaultGradeLevel();
          if (defaultGrade) {
            setSelectedGradeLevel(defaultGrade);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [schoolid, teacherRoles]);

  useEffect(() => {
    if (submitted) {
      const isClassTitleValid = classTitle.length > 0;
      const isGradeValid = selectedGradeLevel.length > 0;

      setClassTitleError(isClassTitleValid ? "" : "Please enter a class name.");
      setGradeError(isGradeValid ? "" : "Please select a grade level.");
    }
  }, [classTitle, selectedGradeLevel, submitted]);

  function handleSubmit(event) {
    event.preventDefault();
    setSubmitted(true);

    const isClassTitleValid = classTitle.length > 0;
    const isGradeValid = selectedGradeLevel.length > 0;

    if (!isClassTitleValid || !isGradeValid) {
      setClassTitleError(
        !isClassTitleValid ? "Please enter a class name." : ""
      );
      setGradeError(!isGradeValid ? "Please select a grade level." : "");
      return;
    }

    setShowModal(true);
  }

  function handleModalConfirm(
    selectedTeacherId,
    inviteColleagueClicked = isInviteColleague
  ) {
    const matchingGrade = gradeLevelOptions.includes(selectedGradeLevel);
    if (matchingGrade) {
      const trialClass = false;
      const setClass = false;
      const grade = selectedGradeLevel;
      const formattedClassTitle = `24-${classTitle}`;
      const finalClassOwner = selectedTeacherId || teacherId;

      createClass(
        formattedClassTitle,
        grade,
        grade === gradeLevelOptions[0],
        grade === gradeLevelOptions[1],
        trialClass,
        false,
        finalClassOwner,
        setClass
      )
        .then(() => {
          if (!inviteColleagueClicked) {
            setTimeout(() => {
              props.router.navigate("/classadmindashboard");
            }, 3000);
          } else {
            setTimeout(() => {
              props.router.navigate("/invitecolleagues", {
                state: {
                  rollNumber: { rollNumber },
                  teacherEmail: { teacherEmail },
                  countryId: { countryId },
                  schoolid: { schoolid },
                  name: { name },
                  teachers: { teachers },
                  fromCreateClass: fromCreateClass,
                },
              });
            }, 3000);
          }
        })
        .catch((error) => {
          console.error("Error creating class:", error);
        });
    }
    setShowModal(false);
  }

  function handleModalClose() {
    setShowModal(false);
  }

  function handleSelectOwner(teacherId) {
    setClassOwner(teacherId);
  }

  function handleInviteColleagueClick() {
    setIsInviteColleague(true); // Set the invite colleague flag
    handleSelectOwner(""); // Reset the owner
    localStorage.removeItem("selectedTeacherId");

    // Wait for the next render cycle where `isInviteColleague` will be true
    setTimeout(() => {
      handleModalConfirm("", true); // Pass true directly
    }, 0); // This allows the state update to be processed before calling the confirm function

    toast.success("Class has been successfully created!");
  }

  const textBoxStyle = {
    border: "1px solid #ddd",
    borderRadius: "0.25rem",
    padding: "1rem",
    marginBottom: "1rem",
    fontSize: "18px",
    fontWeight: "bold",
    textAlign: "center",
  };

  return (
    <div className="form-detail">
      <Form onSubmit={handleSubmit}>
        <div style={textBoxStyle}>
          <p>
            Please name classroom as it appears in your school MIS system e.g
            Aladdin / Arbor / i-SAMS - this will assist with reporting later.
          </p>
        </div>

        <Form.Group as={Row} className="mb-3" controlId="class-name">
          <Form.Label className="create-class-label" column sm="2">
            Class Name
          </Form.Label>
          <Col sm="10">
            <Form.Control
              placeholder="Class ABC..."
              size="md"
              type="text"
              value={`24-${classTitle}`}
              onChange={(e) => setClassTitle(e.target.value.slice(3))}
              isInvalid={submitted && !!classTitleError}
            />
            {submitted && classTitleError && (
              <Form.Control.Feedback type="invalid">
                {classTitleError}
              </Form.Control.Feedback>
            )}
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="grade">
          <Form.Label className="create-class-label" column sm="2">
            Class Level
          </Form.Label>
          <Col>
            <Form.Control
              as="select"
              size="md"
              value={selectedGradeLevel}
              onChange={(e) => setSelectedGradeLevel(e.target.value)}
              isInvalid={submitted && !!gradeError}
            >
              <option value="">Select Class Level...</option>
              {gradeLevelOptions.map((grade, index) => {
                if (
                  countryId === "74bb2897-dbbe-41e3-b547-caee866545cf" &&
                  index === 1
                ) {
                  return null;
                }
                return (
                  <option key={index} value={grade}>
                    {grade}
                  </option>
                );
              })}
            </Form.Control>
            {submitted && gradeError && (
              <Form.Control.Feedback type="invalid">
                {gradeError}
              </Form.Control.Feedback>
            )}
          </Col>
        </Form.Group>

        <div className="float-right">
          <SimpleNavigationButton
            path="/classadmindashboard"
            label="Cancel"
            colour="white"
          />
          <button className="green-button" type="submit">
            Create
          </button>
        </div>
      </Form>

      <ConfirmClassOwnerModal
        show={showModal}
        onConfirm={handleModalConfirm}
        onClose={handleModalClose}
        teacherOptions={teacherOptions}
        onSelectOwner={handleSelectOwner}
        handleInviteColleagueClick={handleInviteColleagueClick} // New prop to handle invite click
      />
    </div>
  );
}

export default withRouter(CreateClassForm);
