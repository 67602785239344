import React from "react";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import "./AssessmentDropdown.css";

function SencoAssessmentDropdown(props) {
  let classIds = props.classIds;
  let teachers = props.teachers;
  let assessmentName = props.assessmentName;
  let schoolid = props.schoolid;
  let allClassesAssessments = props.allClassesAssessments;

  const navigate = useNavigate();

  // Collect and deduplicate assessments based on assessmentId
  const getUniqueAssessments = () => {
    const assessmentsMap = {};

    allClassesAssessments.forEach((classAssessment) => {
      Object.keys(classAssessment.assessments).forEach((assessmentId) => {
        if (!assessmentsMap[assessmentId]) {
          assessmentsMap[assessmentId] = {
            ...classAssessment.assessments[assessmentId],
            id: assessmentId, // Store the unique assessmentId
          };
        }
      });
    });

    return Object.values(assessmentsMap); // Return only unique assessments
  };

  const uniqueAssessments = getUniqueAssessments();

  // Navigate to the assessment results when an item is clicked
  const toSencoAssessmentResults = (assessment) => {
    navigate("/senco/studentsresults", {
      state: {
        assessment: { assessment }, // Pass the whole assessment object
        assessmentId: assessment.id, // Assessment ID
        teachers: { teachers }, // Teacher info
        schoolid: { schoolid }, // School ID
        classIds: { classIds }, // Class IDs
        allClassesAssessments: { allClassesAssessments }, // All assessment data
      },
    });
  };

  return (
    <Dropdown>
      <Dropdown.Toggle
        variant="success"
        style={{
          backgroundColor: "white",
          color: "black",
          border: "solid 1px #2c7693",
          textAlign: "center",
        }}
      >
        {assessmentName || "Select Assessment"}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {uniqueAssessments.map((assessment, index) => (
          <Dropdown.Item
            key={index}
            onClick={() => toSencoAssessmentResults(assessment)}
          >
            {assessment.name.replace(" Instance", "")}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default SencoAssessmentDropdown;
