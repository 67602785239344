import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import Accordion from "react-bootstrap/Accordion";
import TaskResultsTable2 from "./TaskResultsTable2.js";
import secondsToTime from "../services/secondsToTime";
import DeleteAnswerModal from "./DeleteAnswerModal.js";
import "./ResultsHistory.css";

function ResultsHistory(props) {
  let studentResults = props.individualStudentResult;
  let isPrintOut = props.isPrintOut;
  let detailedView = props.detailedView;
  let studentName = props.studentName;
  let teacherid = props.teacherid;
  let schoolid = props.schoolid;

  const [showModal, setShowModal] = useState(false);
  const [modalTask, setModalTask] = useState(null);
  const [modalAssessment, setModalAssessment] = useState(null);
  const [answerId, setAnswerId] = useState(null);
  const [assessmentResults, setAssessmentResults] = useState([]);

  useEffect(() => {
    if (studentResults) {
      setAssessmentResults(studentResults);
    }
  }, [studentResults]);

  let fullTasksArray = [];
  let taskNames = [];
  let tasksArray = [];

  for (let i = 0; i < assessmentResults.length; i++) {
    let studentAssessment = assessmentResults[i];
    let assessmentTasks = studentAssessment.tasks;

    assessmentTasks.sort(function (a, b) {
      return a.order - b.order;
    });

    let totalTime = 0;
    tasksArray = [];
    for (let j = 0; j < assessmentTasks.length; j++) {
      let task = assessmentTasks[j];
      let taskName = task.name;
      let taskScore = Math.round(task.score * 100);
      let taskDuration = task.duration;

      let taskOrder = task.order;

      totalTime += taskDuration;

      let taskAnswers = task.answers;

      fullTasksArray.push(taskOrder + taskName);

      let tasks = {
        [taskName]: taskScore,
      };
      tasksArray.push(tasks);
    }

    studentAssessment.totalTime = totalTime;
  }

  function taskNameOrder() {
    let reducedTaskNameArray = [...new Set(fullTasksArray)];

    reducedTaskNameArray.sort(function (a, b) {
      if (a > b) return 1;
      if (a < b) return -1;
      return 0;
    });

    for (let i = 0; i < reducedTaskNameArray.length; i++) {
      let taskName = reducedTaskNameArray[i];
      let numberRemoved = taskName.replace(/[0-9]/g, "");
      taskNames.push(numberRemoved);
    }
  }
  taskNameOrder();

  function getReadingScoreClass(score) {
    if (score === 0) {
      return "low-color";
    } else if (score > 0 && score <= 20) {
      return "low-color";
    } else if (score > 20 && score <= 40) {
      return "med-color";
    } else if (score > 40 && score <= 100) {
      return "noRisk-color";
    } else {
      return "blah"; // Return an empty string for scores outside the specified ranges
    }
  }

  function calculateReadingScore(assessment) {
    if (!assessment || !assessment.tasks || assessment.tasks.length === 0) {
      return 0; // Default to 0 if there are no tasks or if the assessment is not defined
    }

    // Calculate the reading score based on the assessment's tasks.
    const totalScore = assessment.tasks.reduce(
      (acc, task) => acc + task.score,
      0
    );
    const readingScore = Math.round(
      (totalScore / assessment.tasks.length) * 100
    );

    return readingScore;
  }

  // Iterate through assessment results and calculate reading scores.
  assessmentResults.map((assessment) => {
    assessment.readingScore = calculateReadingScore(assessment);
    return assessment;
  });

  // State to track which accordions are open
  const [visibleAccordions, setVisibleAccordions] = useState(new Set());

  // Toggle accordion visibility
  const showNestedData = (assessmentIndex) => {
    setVisibleAccordions((prevVisible) => {
      const newVisible = new Set(prevVisible);
      if (newVisible.has(assessmentIndex)) {
        newVisible.delete(assessmentIndex);
      } else {
        newVisible.add(assessmentIndex);
      }
      return newVisible;
    });
  };

  // Generate an array of event keys for all tasks
  const allEventKeys = assessmentResults.flatMap(
    (assessment, assessmentIndex) =>
      assessment.tasks.map((_, taskIndex) => `${assessmentIndex}-${taskIndex}`)
  );

  // console.log("assessmentResults:", assessmentResults)

  const [sortCriteria, setSortCriteria] = useState({
    field: null,
    order: null,
  });

  const sortData = (field) => {
    const sortField = field === "atRisk" ? "readingScore" : field;
    setSortCriteria((prev) => {
      const isAscending = prev.field === sortField && prev.order === "asc";
      return { field: sortField, order: isAscending ? "desc" : "asc" };
    });
  };

  const defaultOrder = ["Baseline", "Midpoint", "End of Year"];

  const getOrderIndex = (name) => {
    if (name.toLowerCase().includes("baseline")) return 0;
    if (name.toLowerCase().includes("midpoint")) return 1;
    if (name.toLowerCase().includes("end of year")) return 2;
    return -1;
  };

  const sortedAssessmentResults = useMemo(() => {
    let sortedData = [...assessmentResults];

    if (sortCriteria.field) {
      sortedData.sort((a, b) => {
        if (taskNames.includes(sortCriteria.field)) {
          let scoreA =
            a.tasks.find((t) => t.name === sortCriteria.field)?.score || 0;
          let scoreB =
            b.tasks.find((t) => t.name === sortCriteria.field)?.score || 0;
          return sortCriteria.order === "asc"
            ? scoreA - scoreB
            : scoreB - scoreA;
        } else if (sortCriteria.field === "readingScore") {
          return sortCriteria.order === "asc"
            ? a.readingScore - b.readingScore
            : b.readingScore - a.readingScore;
        } else {
          return sortCriteria.order === "asc"
            ? a[sortCriteria.field] - b[sortCriteria.field]
            : b[sortCriteria.field] - a[sortCriteria.field];
        }
      });
    } else {
      // Default sorting based on keyword matching
      sortedData.sort((a, b) => {
        const indexA = getOrderIndex(a.name);
        const indexB = getOrderIndex(b.name);

        // If both assessments are matched by the default order, sort by their order
        if (indexA !== -1 && indexB !== -1) {
          return indexA - indexB;
        }
        // If only one of the assessments is matched by the default order, it should come first
        if (indexA !== -1) return -1;
        if (indexB !== -1) return 1;

        // If neither assessment is matched by the default order, maintain their order
        return 0;
      });
    }

    return sortedData;
  }, [assessmentResults, sortCriteria, taskNames]);

  function renderTaskScore(tasks, taskName) {
    // console.log("Received tasks:", tasks);
    // console.log("Looking for task:", taskName);

    const task = tasks.find((t) => t.name === taskName);
    // console.log("Found task:", task);

    if (!task) return "";

    const score = Math.round(task.score * 100);
    let scoreClass = "";

    if (score === 0) {
      scoreClass = "low-color";
    } else if (score > 0 && score <= 20) {
      scoreClass = "low-color";
    } else if (score > 20 && score < 40) {
      scoreClass = "med-color";
    } else if (score >= 40 && score <= 100) {
      scoreClass = "noRisk-color";
    }

    // console.log(`Rendered score for ${taskName}:`, score, scoreClass);

    return <span className={`task-score ${scoreClass}`}>{score}%</span>;
  }

  const getSortIcon = (fieldName) => {
    if (sortCriteria.field === fieldName) {
      return sortCriteria.order === "asc"
        ? process.env.PUBLIC_URL + "/assets/imgs/desc-icon-active.png"
        : process.env.PUBLIC_URL + "/assets/imgs/asc-icon-active.png";
    }
    return process.env.PUBLIC_URL + "/assets/imgs/asc-icon-default.png";
  };

  const handleDeleteTask = useCallback(
    (event, assessmentIndex, taskIndex) => {
      event.stopPropagation(); // Prevent the event from bubbling up
      event.preventDefault(); // Prevent the default action

      const assessment = assessmentResults[assessmentIndex];
      const task = assessment.tasks[taskIndex];
      setModalTask(task?.name);
      setModalAssessment(assessment?.name);
      setAnswerId(task?.answerid);
      setShowModal(true);
    },
    [assessmentResults]
  );

  const handleModalClose = () => setShowModal(false);

  const handleDelete = (taskName, deletionReason) => {
    setAssessmentResults((prevResults) => {
      return prevResults.map((assessment) => {
        return {
          ...assessment,
          tasks: assessment.tasks.filter((task) => task.name !== taskName),
        };
      });
    });
  };

  console.log(assessmentResults);
  console.log(sortedAssessmentResults);

  return (
    <>
      <Row>
        <Col>
          <div className="">
            <div className="print-only-header">
              <thead className="table-header print-thead">
                <tr>
                  <th
                    colSpan={taskNames.length + 5}
                    className="at-risk-filter-row"
                  >
                    <div className="at-risk-filter">
                      <img
                        src={
                          process.env.PUBLIC_URL + "/assets/imgs/0-20Bar.png"
                        }
                        alt="0-20%"
                        className="risk-bar"
                      />
                      <img
                        src={
                          process.env.PUBLIC_URL + "/assets/imgs/21-40Bar.png"
                        }
                        alt="21-40%"
                        className="risk-bar"
                      />
                    </div>
                  </th>
                </tr>
                <tr>
                  <th
                    className="header-th-left header-size-results"
                    onClick={() => sortData("name")}
                  >
                    <div className="header-content">
                      Assessment
                      <img
                        className="sort-icon"
                        src={getSortIcon("name")}
                        alt="Sort"
                      />
                    </div>
                  </th>
                  <th
                    className="header-th header-size-results"
                    onClick={() => sortData("readingScore")}
                  >
                    <div className="header-content">
                      Reading Score
                      <img
                        className="sort-icon"
                        src={getSortIcon("readingScore")}
                        alt="Sort"
                      />
                    </div>
                  </th>
                  <th
                    className="header-th header-size-results"
                    onClick={() => sortData("atRisk")}
                  >
                    <div className="header-content">
                      At-Risk
                      <img
                        className="sort-icon"
                        src={getSortIcon("atRisk")}
                        alt="Sort"
                      />
                    </div>
                  </th>
                  {taskNames.map((taskName) => (
                    <th
                      className="header-th header-size-results"
                      key={taskName}
                      onClick={() => sortData(taskName)}
                    >
                      <div className="header-content">
                        {taskName}
                        <img
                          className="sort-icon"
                          src={getSortIcon(taskName)}
                          alt="Sort"
                        />
                      </div>
                    </th>
                  ))}
                  <th
                    className="header-th header-size-results"
                    onClick={() => sortData("totalTime")}
                  >
                    <div className="header-content">
                      Time
                      <img
                        className="sort-icon"
                        src={getSortIcon("totalTime")}
                        alt="Sort"
                      />
                    </div>
                  </th>
                  {!isPrintOut ? (
                    <th className="header-th header-size-results">
                      <div className="header-content">View</div>
                    </th>
                  ) : null}
                </tr>
              </thead>
            </div>
            <div className="table-responsive-wrapper">
              <Table className="custom-table results-history-table">
                <thead className="table-header print-thead">
                  <tr>
                    <th
                      colSpan={taskNames.length + 5}
                      className="at-risk-filter-row"
                    >
                      <div className="at-risk-filter">
                        <img
                          src={
                            process.env.PUBLIC_URL + "/assets/imgs/0-20Bar.png"
                          }
                          alt="0-20%"
                          className="risk-bar"
                        />
                        <img
                          src={
                            process.env.PUBLIC_URL + "/assets/imgs/21-40Bar.png"
                          }
                          alt="21-40%"
                          className="risk-bar"
                        />
                      </div>
                    </th>
                  </tr>
                  <tr>
                    <th
                      className="header-th-left header-size-results"
                      onClick={() => sortData("name")}
                    >
                      <div className="header-content">
                        Screener
                        <img
                          className="sort-icon"
                          src={getSortIcon("name")}
                          alt="Sort"
                        />
                      </div>
                    </th>
                    <th
                      className="header-th header-size-results"
                      onClick={() => sortData("readingScore")}
                    >
                      <div className="header-content">
                        Reading Score
                        <img
                          className="sort-icon"
                          src={getSortIcon("readingScore")}
                          alt="Sort"
                        />
                      </div>
                    </th>
                    <th
                      className="header-th header-size-results"
                      onClick={() => sortData("atRisk")}
                    >
                      <div className="header-content">
                        At-Risk
                        <img
                          className="sort-icon"
                          src={getSortIcon("atRisk")}
                          alt="Sort"
                        />
                      </div>
                    </th>
                    {taskNames.map((taskName) => (
                      <th
                        className="header-th header-size-results"
                        key={taskName}
                        onClick={() => sortData(taskName)}
                      >
                        <div className="header-content">
                          {taskName}
                          <img
                            className="sort-icon"
                            src={getSortIcon(taskName)}
                            alt="Sort"
                          />
                        </div>
                      </th>
                    ))}
                    <th
                      className="header-th header-size-results"
                      onClick={() => sortData("totalTime")}
                    >
                      <div className="header-content">
                        Time
                        <img
                          className="sort-icon"
                          src={getSortIcon("totalTime")}
                          alt="Sort"
                        />
                      </div>
                    </th>
                    {!isPrintOut ? (
                      <th className="header-th header-size-results">
                        <div className="header-content">View</div>
                      </th>
                    ) : null}
                  </tr>
                </thead>
                {sortedAssessmentResults.map((assessment, assessmentIndex) =>
                  assessment.tasks && assessment.tasks.length > 0 ? (
                    <React.Fragment key={assessmentIndex}>
                      <tbody className="result-history-table-rows">
                        <tr
                          id={assessment.name
                            .replace(/\s+/g, "-")
                            .toLowerCase()}
                          className="results-history-table-row centre-item"
                        >
                          <td className="align-left">{assessment.name}</td>
                          <td className="centre-item task-badge-parent">
                            {assessment.readingScore ? (
                              <span
                                className={`reading-score ${getReadingScoreClass(
                                  assessment.readingScore
                                )}`}
                              >
                                {assessment.readingScore}%
                              </span>
                            ) : null}
                          </td>

                          <td>
                            {assessment.readingScore !== undefined &&
                            assessment.readingScore < 20 ? (
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/imgs/risk-flag-red.png"
                                }
                                className="flag-size-results"
                                alt="Red Flag"
                              />
                            ) : assessment.readingScore >= 20 &&
                              assessment.readingScore <= 40 ? (
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/imgs/risk-flag-yellow.png"
                                }
                                className="flag-size-results"
                                alt="Yellow Flag"
                              />
                            ) : null}
                          </td>
                          {taskNames.map((taskName) => (
                            <td
                              className="results-task-score centre-item task-badge-parent"
                              data-value={taskName
                                .replace(/\s+/g, "-")
                                .toLowerCase()}
                              key={taskName}
                            >
                              {renderTaskScore(assessment.tasks, taskName)}
                            </td>
                          ))}
                          <td
                            className="results-task-score centre-item"
                            data-value={assessment.name}
                          >
                            {secondsToTime(assessment.totalTime)}
                          </td>
                          {!isPrintOut ? (
                            <td data-toggle="toggle">
                              <img
                                className="collapse-click"
                                onClick={() => showNestedData(assessmentIndex)}
                                src={
                                  visibleAccordions.has(assessmentIndex)
                                    ? "../assets/imgs/minus-icon-small.png"
                                    : "../assets/imgs/plus-icon-small.png"
                                }
                              />
                            </td>
                          ) : null}
                        </tr>
                      </tbody>
                      {detailedView && (
                        <td
                          colSpan="12"
                          id={
                            assessment.name.replace(/\s+/g, "-").toLowerCase() +
                            "-data"
                          }
                          className={`result-accordian-width ${
                            visibleAccordions.has(assessmentIndex) || isPrintOut
                              ? ""
                              : "hide"
                          }`}
                        >
                          <Accordion
                            className="results-accordion"
                            defaultActiveKey={isPrintOut ? allEventKeys : []}
                          >
                            {assessment.tasks.map((task, taskIndex) => (
                              <Accordion.Item
                                eventKey={`${assessmentIndex}-${taskIndex}`}
                                key={`${assessmentIndex}-${taskIndex}`}
                                style={{ border: "white" }}
                              >
                                <Accordion.Header className="selected-assessment-accordion-head">
                                  <Row className="task-results-table-row">
                                    <Col className="col-1">
                                      <button
                                        className="delete-button"
                                        onClick={(e) =>
                                          handleDeleteTask(
                                            e,
                                            assessmentIndex,
                                            taskIndex
                                          )
                                        }
                                      >
                                        &#10005;
                                      </button>
                                    </Col>
                                    <Col className="col-9 align-left">
                                      {task.name}
                                    </Col>
                                    <Col>{secondsToTime(task.duration)}</Col>
                                  </Row>
                                </Accordion.Header>
                                <Accordion.Body>
                                  <TaskResultsTable2
                                    taskAnswers={task.answers}
                                  />
                                </Accordion.Body>
                              </Accordion.Item>
                            ))}
                          </Accordion>
                        </td>
                      )}
                    </React.Fragment>
                  ) : null
                )}
              </Table>
            </div>
          </div>
        </Col>
      </Row>
      <DeleteAnswerModal
        show={showModal}
        handleClose={handleModalClose}
        taskName={modalTask}
        studentName={studentName}
        assessmentName={modalAssessment}
        handleDelete={handleDelete}
        answerId={answerId}
        teacherid={teacherid}
      />
    </>
  );
}

export default ResultsHistory;
