import React from "react";
import { Link } from "react-router-dom";

import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import "./ReturnToClassOverview.css";

function ReturnToAssessmentOverview(props) {
  let classDetails = props.classDetails;
  let teachers = props.teachers;
  let name = props.name;
  let gradeLevel = props.gradeLevel;
  let teacherRoles = props.teacherRoles;

  return (
    <Link
      to={`/assessmentsdashboard`}
      state={{
        classDetails: { classDetails },
        teachers: { teachers },
        name: { name },
        gradeLevel: { gradeLevel },
        teacherRoles: { teacherRoles },
      }}
    >
      <button className="blue-button">Back</button>
    </Link>
  );
}

export default ReturnToAssessmentOverview;
