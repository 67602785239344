import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { shareClass } from "../services/APIClient";

function AlreadyInvitedModal({
  show,
  handleClose,
  alreadyInvitedColleagues,
  teachers,
  classes,
}) {
  const teachersList = teachers.teachers;

  // State to manage selected teacher and class pairs
  const [shareEntries, setShareEntries] = useState([
    { teacherId: "", classId: "" },
  ]);

  // Handle adding a new entry for sharing
  const addShareEntry = () => {
    setShareEntries([...shareEntries, { teacherId: "", classId: "" }]);
  };

  // Handle changes to teacher and class selections
  const handleShareEntryChange = (index, field, value) => {
    const updatedEntries = shareEntries.map((entry, i) =>
      i === index ? { ...entry, [field]: value } : entry
    );
    setShareEntries(updatedEntries);
  };

  const handleShareClasses = () => {
    shareEntries.forEach(({ teacherId, classId }) => {
      if (teacherId && classId) {
        // Call shareClass function for each pair
        shareClass(classId, teacherId);
        console.log(`Class ${classId} shared with teacher ID: ${teacherId}`);
      }
    });
    handleClose(); // Close the modal after sharing
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Already Invited Colleagues</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          The following colleagues have already been invited/are already a user:
        </p>
        <ul>
          {alreadyInvitedColleagues.map((colleague, index) => (
            <li key={index}>{colleague.email}</li>
          ))}
        </ul>
        <p>Click the share class button to share classes with teachers.</p>

        <hr />
        <p>Select the teacher and class to share:</p>

        {shareEntries.map((entry, index) => (
          <div key={index} className="mb-3">
            <Form.Group controlId={`teacherSelect${index}`}>
              <Form.Label>Select Teacher</Form.Label>
              <Form.Select
                value={entry.teacherId}
                onChange={(e) =>
                  handleShareEntryChange(index, "teacherId", e.target.value)
                }
              >
                <option value="">Choose a teacher...</option>
                {teachersList.map((teacher) => (
                  <option key={teacher.id} value={teacher.id}>
                    {teacher.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group controlId={`classSelect${index}`}>
              <Form.Label>Select Class</Form.Label>
              <Form.Select
                value={entry.classId}
                onChange={(e) =>
                  handleShareEntryChange(index, "classId", e.target.value)
                }
              >
                <option value="">Choose a class...</option>
                {classes.map((cls) => (
                  <option key={cls.id} value={cls.id}>
                    {cls.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </div>
        ))}

        <Button variant="outline-primary" onClick={addShareEntry}>
          Add Another Entry
        </Button>
      </Modal.Body>
      <Modal.Footer>
        <button className="grey-button" onClick={handleClose}>
          Close
        </button>
        <button className="green-button" onClick={handleShareClasses}>
          Share Class
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default AlreadyInvitedModal;
