import { React, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  getClass,
  getStudents,
  getQR,
  createSpecialEdStudent,
} from "../services/APIClient";

import HeadingBanner from "../components/HeadingBanner";
import LeftPanel from "../components/LeftPanel";
import ClassOverview from "../components/ClassOverview";
import OrientationPrompt from "../components/OrientationPrompt";
import { Container, Row, Col } from "react-bootstrap";
import "../components/ClassOverview.css";

function ClassDetail(props) {
  const location = useLocation();
  const [isFromAddStudents, setIsFromAddStudents] = useState(false);

  let classDetails = location.state.classDetails.classDetails;
  let name = location.state.name.name;
  let teachers = location.state.teachers.teachers;
  let gradeLevel = location.state.gradeLevel.gradeLevel;
  let rollNumber = location.state?.rollNumber?.rollNumber;
  let teacherEmail = location.state?.teacherEmail?.teacherEmail;
  let classid = location.state.classDetails.classDetails.id;
  let teacherid = classDetails.teacherId;
  let schoolid = location.state?.schoolid.schoolid;
  let isSpecialEdClass = classDetails.specialed_class;
  let teacherRoles = location.state?.teacherRoles?.teacherRoles;

  const [group, setGroup] = useState("");
  const [studentsResponse, setStudentsResponse] = useState("");
  const [qrCodeDetails, setQRCodeDetails] = useState("");
  const [loading, setLoading] = useState(true);
  const [isPortrait, setIsPortrait] = useState(
    window.matchMedia("(orientation: portrait)").matches
  );

  useEffect(() => {
    // Check if we came from the /addstudents page using the state flag
    if (location.state && location.state.fromAddStudents) {
      setIsFromAddStudents(true);
    }

    const handleOrientationChange = () => {
      setIsPortrait(window.innerWidth < window.innerHeight);
    };

    window.addEventListener("resize", handleOrientationChange);

    return () => {
      window.removeEventListener("resize", handleOrientationChange);
    };
  }, [location.state]);

  useEffect(() => {
    window.HubSpotConversations?.widget?.refresh();
  }, []);

  useEffect(() => {
    getClass(classid).then((response) => {
      setGroup(response);
    });

    getStudents(classid).then((response) => {
      setStudentsResponse(response);
    });

    getQR(classid).then((response) => {
      setQRCodeDetails(response);
    });
  }, [classid, location.key]);

  useEffect(() => {
    // Check if all data is loaded before setting loading to false
    if (group && studentsResponse && qrCodeDetails) {
      setLoading(false);

      // Conditionally trigger API call based on state
      if (isSpecialEdClass && studentsResponse.students && isFromAddStudents) {
        // Transform student data into the required format and wrap in an array
        const formattedStudents = studentsResponse.students
          .filter((student) => student.currentGrade || student.countryGradeId) // Filter out students without necessary data
          .map((student) => ({
            studentId: student.id,
            countrygradeId: student.countryGradeId || "Unknown", // Default value if not provided
            currentGrade: student.currentGrade || "Unknown", // Default value if not provided
            dateadded: new Date().toISOString(), // Current date in ISO format
          }));

        // Make API call with array data
        createSpecialEdStudent(formattedStudents)
          .then((data) => {})
          .catch((error) => {
            console.error(
              "Error creating SpecialEdStudents:",
              error.response ? error.response.data : error.message
            );
          });
      }
    }
  }, [
    group,
    studentsResponse,
    qrCodeDetails,
    isSpecialEdClass,
    isFromAddStudents,
  ]);

  let students = [
    ...(studentsResponse.students || []),
    ...(studentsResponse.additionalStudents || []),
  ];

  return (
    <Container fluid className="vh-100">
      <HeadingBanner
        name={name}
        isLoggedIn={true}
        cloud={true}
        teacherid={teacherid}
      />
      <Row className="h-100">
        <LeftPanel />
        <Col sm={{ span: 10, offset: 2 }} className="blue-background">
          {loading ? (
            <div className="spinner-container">
              <div className="big-ball-spinner"></div>
            </div>
          ) : (
            <ClassOverview
              group={group}
              classDetails={classDetails}
              teachers={teachers}
              sharedWith={group.sharedWith}
              students={students}
              gradeLevel={gradeLevel}
              name={name}
              qrCodeDetails={qrCodeDetails}
              rollNumber={rollNumber}
              teacherEmail={teacherEmail}
              schoolid={schoolid}
              isSpecialEdClass={isSpecialEdClass}
              teacherRoles={teacherRoles}
            />
          )}
        </Col>
      </Row>
      {isPortrait && <OrientationPrompt />}
    </Container>
  );
}

export default ClassDetail;
