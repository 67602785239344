import React from "react";
import { useNavigate } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import "./AssessmentStatus.css";

function SencoResultsStatus({
  allClassesAssessments,
  teachers,
  schoolid,
  classIds,
  name,
}) {
  // Flatten and deduplicate assessments based on `assessmentid`
  const uniqueAssessments = Object.values(
    allClassesAssessments.reduce((acc, classAssessments) => {
      Object.keys(classAssessments.assessments).forEach((assessmentId) => {
        if (!acc[assessmentId]) {
          acc[assessmentId] = {
            ...classAssessments.assessments[assessmentId],
            id: assessmentId, // Store assessmentId directly
          };
        }
      });
      return acc;
    }, {})
  );

  // Helper function to get the image name for an assessment
  const getIconFileName = (assessmentName) => {
    let name = assessmentName.toLowerCase();

    // Logic for season-based assessments
    if (
      name.includes("baseline") ||
      name.includes("point 1") ||
      name.includes("autumn")
    ) {
      return "autumn";
    } else if (
      name.includes("midpoint") ||
      name.includes("point 2") ||
      name.includes("spring")
    ) {
      return "spring";
    } else if (
      name.includes("end of year") ||
      name.includes("point 3") ||
      name.includes("summer")
    ) {
      return "summer";
    }

    // For other task-based assessments
    const words = name.split(" ");
    return words.slice(0, 2).join(""); // Combine first two words for image name
  };

  const navigate = useNavigate();

  // Navigate to results when an assessment is clicked
  const toSencoAssessmentResults = (assessment) => {
    navigate("/senco/studentsresults", {
      state: {
        assessment: { assessment },
        assessmentId: assessment.id,
        teachers: { teachers },
        schoolid: { schoolid },
        classIds: { classIds },
        name: { name },
        allClassesAssessments: { allClassesAssessments },
      },
    });
  };

  return (
    <Row>
      {uniqueAssessments.length === 0 ? (
        <Col>
          <Alert variant="info" className="text-center">
            <FontAwesomeIcon
              icon={faExclamationCircle}
              size="3x"
              className="mb-3"
            />
            <h4>No Results Available</h4>
            <p>
              Once your students begin screening, the results will start to
              populate here.
            </p>
          </Alert>
        </Col>
      ) : (
        uniqueAssessments.map((assessment, index) => (
          <Col className="col-4" key={index}>
            <button
              className="light-blue-button assessment-button"
              onClick={() => toSencoAssessmentResults(assessment)}
            >
              {assessment.name.replace(" Instance", "")} <br />
              <img
                src={`/assets/imgs/${getIconFileName(
                  assessment.name
                )}-icon.png`}
                className="season-assessment-icon"
                alt={assessment.name}
              />
            </button>
          </Col>
        ))
      )}
    </Row>
  );
}

export default SencoResultsStatus;
