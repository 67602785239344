import React, { useRef } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

import ReactToPrint from "react-to-print";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement, // Register BarElement for bar charts
  CategoryScale,
  LinearScale
);

// Create a forward ref component for the Bar chart
const TaskDetailChart = React.forwardRef(
  ({ taskName, data, className }, ref) => {
    // Prepare the data for the new chart
    const taskData = {
      labels: data.map((d) => d.className), // Class names as labels
      datasets: [
        {
          label: taskName,
          data: data.map((d) => d.score), // Task scores
          backgroundColor: "rgba(75, 192, 192, 0.7)", // Set the background color for bars
        },
      ],
    };

    const options = {
      scales: {
        y: {
          beginAtZero: true,
          ticks: {
            stepSize: 10,
            min: 0,
            max: 100,
          },
          title: {
            display: true,
            text: "Score",
          },
        },
        x: {
          title: {
            display: true,
            text: "Class Name",
          },
        },
      },
      plugins: {
        legend: {
          display: true,
        },
      },
    };

    return (
      <div ref={ref}>
        {/* Class Name Section */}
        <h2 className="class-name-print">{className}</h2>
        <Bar data={taskData} options={options} /> {/* Change Line to Bar */}
      </div>
    );
  }
);

const TaskDetailChartContainer = ({ taskName, data, className }) => {
  const componentRef = useRef(); // Create a ref for the component to print

  return (
    <div>
      {/* Print Button */}
      <ReactToPrint
        trigger={() => <button className="blue-button">Print Graph</button>}
        content={() => componentRef.current}
      />

      {/* The chart component */}
      <TaskDetailChart
        ref={componentRef}
        taskName={taskName}
        data={data}
        className={className}
      />
    </div>
  );
};

export default TaskDetailChartContainer;
