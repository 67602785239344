import React, { useState, useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";

function EditClassDetails({ name, classDetails, group, sendUpdateClassName }) {
  const dateCreated = classDetails.date_created;
  const yearCreated = dateCreated ? dateCreated.substring(0, 4) : "";

  // Initialize the state with the current class name or group.name
  const [updatedName, setUpdatedName] = useState(group.name || name || "");

  // Effect to update state if props change (name or group.name)
  useEffect(() => {
    setUpdatedName(group.name || name || "");
  }, [name, group.name]);

  // Handle class name changes
  const nameUpdate = (e) => {
    const inputName = e.target.value.trim();
    // Allow editing but prevent empty values
    const newName = inputName.startsWith("24-") ? inputName : `24-${inputName}`;
    setUpdatedName(newName);
  };

  // Handle onBlur to reset to group.name if input is empty
  const handleBlur = () => {
    if (updatedName.trim() === "") {
      setUpdatedName(group.name); // Reset to group.name if input is left empty
    }
  };

  // Effect to call sendUpdateClassName when name changes and is not empty
  useEffect(() => {
    if (updatedName !== name && updatedName.trim() !== "") {
      console.log("Sending Updated Name:", updatedName); // Log the updated name
      sendUpdateClassName(updatedName);
    }
  }, [updatedName, name, sendUpdateClassName]);

  return (
    <Form className="form">
      <div
        style={{
          border: "1px solid #ddd",
          borderRadius: "0.25rem",
          padding: "1rem",
          marginBottom: "1rem",
          fontSize: "18px",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        <p>
          Please name the classroom as it appears in your school MIS system,
          e.g., Aladdin / Arbor / i-SAMS - this will assist with reporting later
        </p>
      </div>

      {/* Class Name Field */}
      <Form.Group as={Row} className="mb-3" controlId="class-name">
        <Form.Label className="label" column sm="4">
          <p>Class Name</p>
        </Form.Label>
        <Col sm="8">
          <Form.Control
            placeholder={group.name}
            size="md"
            type="text"
            value={updatedName}
            onChange={nameUpdate}
            onBlur={handleBlur} // Reset to group.name if left empty onBlur
          />
        </Col>
      </Form.Group>

      {/* School Year Field */}
      <Form.Group as={Row} className="mb-3" controlId="year">
        <Form.Label className="label" column sm="4">
          <p>School Year</p>
        </Form.Label>
        <Col sm="8">
          <Form.Control
            placeholder={yearCreated}
            size="md"
            type="text"
            disabled
          />
        </Col>
      </Form.Group>
    </Form>
  );
}

export default EditClassDetails;
