import React from "react";
import { useNavigate } from "react-router-dom";
import "./StudentSettingsButton.css";

function StudentSettingsButton(props) {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/student/settings", {
      state: {
        classDetails: props.classDetails,
        student: props.student,
        schoolid: props.schoolid,
        teacherRoles: props.teacherRoles,
      },
    });
  };

  return (
    <button className="blue-button settings-cog" onClick={handleButtonClick}>
      Edit Student
    </button>
  );
}

export default StudentSettingsButton;
