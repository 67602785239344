import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/esm/Table";
import Checkbox from "./Checkbox";
import "./SelectClassStudents.css";

function SelectClassStudents(props) {
  const {
    classStudents,
    selectStudentId,
    setSelectStudentId,
    selectAssessmentId,
    gradeLevel1,
    gradeLevel2
  } = props;

  const [completedAssessmentIcons, setCompletedAssessmentIcons] = useState({});

  const [fetchedAssessmentIds, setFetchedAssessmentIds] = useState({});

  const gradeLevel2Ids = new Set([
    "927b2379-998c-4424-af56-1f82e0144765",
    "9b962947-4f59-4e19-a725-40601b66e8ff",
    "08e97976-f32f-4ad6-a2df-7a2be0789d70",
  ]);

  const fetchCompletedAssessmentIcons = () => {
    const icons = {};
    const idsMap = {};

    classStudents?.forEach((student) => {
      if (student.assessments) {
        // Filter out assessments based on grade level conditions
        const filteredAssessments = student.assessments.filter(
          (assessment) => {
            const isCompleted = assessment.completed && !assessment.assessment?.task;
            if (gradeLevel2) {
              return isCompleted && gradeLevel2Ids.has(assessment.assessmentid);
            }
            return isCompleted;
          }
        );

        // Map the filtered assessments to icons
        icons[student.id] = filteredAssessments
          .map((assessment) => ({
            name: assessment.name,
            icon: getAssessmentIconName(assessment.name),
            assessment: assessment,
          }))
          .sort(
            (a, b) => getAssessmentOrder(a.name) - getAssessmentOrder(b.name)
          );

        // Store the assessment IDs for later use
        idsMap[student.id] = filteredAssessments.map(
          (assessment) => assessment.assessmentid
        );
      } else {
        icons[student.id] = [];
        idsMap[student.id] = [];
      }
    });

    setFetchedAssessmentIds(idsMap);
    setCompletedAssessmentIcons(icons);
  };

  console.log("fetchedAssessmentIds:", fetchedAssessmentIds)
  console.log("completedAssessmentIcons:", completedAssessmentIcons)
  console.log("classStudents:", classStudents)
  console.log("selectAssessmentId:", selectAssessmentId)


  useEffect(() => {
    fetchCompletedAssessmentIcons();
  }, [classStudents]);

  const getAssessmentIconName = (assessmentName) => {
    assessmentName = assessmentName.toLowerCase();
    if (
      assessmentName.includes("baseline") ||
      assessmentName.includes("point 1") ||
      assessmentName.includes("autumn")
    ) {
      return "autumn";
    } else if (
      assessmentName.includes("midpoint") ||
      assessmentName.includes("point 2") ||
      assessmentName.includes("spring")
    ) {
      return "spring";
    } else if (
      assessmentName.includes("end of year") ||
      assessmentName.includes("point 3") ||
      assessmentName.includes("summer")
    ) {
      return "summer";
    } else {
      const nameParts = assessmentName
        .split(" ")
        .filter((part) => part !== "instance");
      console.log(nameParts.slice(0, 2).join("").toLowerCase());
      return nameParts.slice(0, 2).join("").toLowerCase();
    }
  };

  const getAssessmentOrder = (assessmentName) => {
    assessmentName = assessmentName.toLowerCase();
    if (
      assessmentName.includes("baseline") ||
      assessmentName.includes("point 1") ||
      assessmentName.includes("autumn")
    ) {
      return 1;
    } else if (
      assessmentName.includes("midpoint") ||
      assessmentName.includes("point 2") ||
      assessmentName.includes("spring")
    ) {
      return 2;
    } else if (
      assessmentName.includes("end of year") ||
      assessmentName.includes("point 3") ||
      assessmentName.includes("summer")
    ) {
      return 3;
    } else {
      return 4;
    }
  };

  const [isCheckAll, setIsCheckAll] = useState(false);

  const handleClick = (e) => {
    const { id, checked } = e.target;
    setSelectStudentId((prevIds) => {
      if (checked) {
        return [...prevIds, id];
      } else {
        return prevIds.filter((item) => item !== id);
      }
    });
  };

  const handleSelectAll = () => {
    setIsCheckAll((prevIsCheckAll) => {
      setSelectStudentId((prevIds) => {
        // Filter students who are eligible for screener assignment
        const eligibleStudents = classStudents.filter(
          (classStudent) =>
            !classStudent.disabled && // Not disabled
            !(classStudent.assessments && classStudent.assessments.some(assessment => !assessment.completed)) && // No pending assessments
            !fetchedAssessmentIds[classStudent.id]?.includes(selectAssessmentId) // Has not already been assigned this screener
        ).map(classStudent => classStudent.id);
  
        // If we are selecting all, merge the existing selected IDs with the eligible students
        if (!prevIsCheckAll) {
          return [...new Set([...prevIds, ...eligibleStudents])];
        } else {
          // If we are deselecting all, remove all eligible students from the selected IDs
          return prevIds.filter(id => !eligibleStudents.includes(id));
        }
      });
  
      return !prevIsCheckAll; // Toggle the isCheckAll state
    });
  };
  

  return (
    <Row>
      <Col>
        {classStudents?.length > 0 ? (
          <div>
            <h3 className="select-student-header">
              Class Students ({classStudents?.length})
            </h3>
            <p>Tick "Select all" to select all students for this screener.</p>
            <Table className="custom-table">
              <thead className="table-header">
                <tr>
                  <th>Name</th>
                  <th>Completed Screeners</th>
                  <th className="header-th">Current</th>
                  <th>
                    <div className="select-all">
                      {classStudents.some(
                        (student) =>
                          !student.disabled &&
                          !(
                            student.assessments &&
                            student.assessments.some(
                              (assessment) => !assessment.completed
                            )
                          )
                      ) ? (
                        <>
                          <Checkbox
                            type="checkbox"
                            name="selectAll"
                            id="selectAll"
                            handleClick={handleSelectAll}
                            isChecked={isCheckAll}
                          />
                          Select All
                        </>
                      ) : (
                        <span>All have screeners assigned</span>
                      )}
                    </div>
                  </th>
                </tr>
              </thead>

              <tbody>
                {classStudents.map((classStudent) => {
                  const hasAssessmentMatch =
                    fetchedAssessmentIds[classStudent.id]?.includes(
                      selectAssessmentId
                    );
                  const hasIncompleteAssessment =
                    classStudent.assessments?.some(
                      (assessment) => !assessment.completed
                    );

                  return (
                    <tr key={classStudent.id} className="table-row">
                      <td className="table-data">{classStudent.name}</td>
                      <td>
                        {completedAssessmentIcons[classStudent.id]?.map(
                          (assessment, index) => (
                            <span key={index} className="assessment-assigned">
                              <img
                                src={`/assets/imgs/${assessment.icon}-icon.png`}
                                alt={`${assessment.name} Icon`}
                                className="season-assessment-icon-small"
                              />
                            </span>
                          )
                        )}
                      </td>
                      <td>
                        {classStudent.assessments &&
                          classStudent.assessments.some(
                            (assessment) => !assessment.completed
                          ) ? (
                          classStudent.assessments.map((assessment, index) =>
                            !assessment.completed ? (
                              <span key={index} className="assessment-assigned">
                                <img
                                  src={`/assets/imgs/${getAssessmentIconName(assessment.name) ||
                                    assessment.name.split(" ")[0].toLowerCase()
                                    }-icon.png`}
                                  alt={`${assessment.name} Icon`}
                                  className="season-assessment-icon-small"
                                />
                              </span>
                            ) : null
                          )
                        ) : (
                          <span>None</span>
                        )}
                      </td>
                      <td>
                        {classStudent.disabled ||
                          hasIncompleteAssessment ||
                          hasAssessmentMatch ? null : (
                          <Checkbox
                            key={classStudent.id}
                            type="checkbox"
                            name={classStudent.name}
                            id={classStudent.id}
                            handleClick={handleClick}
                            isChecked={selectStudentId.includes(
                              classStudent.id
                            )}
                            isDisabled={false}
                          />
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        ) : (
          <div className="no-students-message">
            <h3>No Students have been added to your class yet.</h3>
            <p>
              To do this, please go to Admin, then to My Classes and select the
              class you want to add students to. From here, select "Add Students"
              and add your students to your class. Thank you.
            </p>
          </div>
        )}
      </Col>
    </Row>
  );
}

export default SelectClassStudents;
