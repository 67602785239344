import { React, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  getClassesBySchoolId,
  getClassAssessmentsForMultipleClasses,
} from "../services/APIClient";
import HeadingBanner from "../components/HeadingBanner";
import LeftPanel from "../components/LeftPanel";
import OrientationPrompt from "../components/OrientationPrompt";
import SencoResultClassOverview from "../components/SencoResultsClassOverview";
import { Container, Row, Col } from "react-bootstrap";
import "../components/ClassOverview.css";

function SencoResultDetail(props) {
  const location = useLocation();
  let schoolid = location?.state?.schoolid;
  let teacherid = location?.state?.teacherid?.teacherid;
  let name = location.state.name.name;
  let teachers = location.state.teachers.teachers;
  let gradeLevel = location.state?.gradeLevel?.gradeLevel;

  const [classes, setClasses] = useState([]);
  const [classIds, setClassIds] = useState([]);
  const [allClassesAssessments, setAllClassesAssessments] = useState([]);

  const [isPortrait, setIsPortrait] = useState(
    window.matchMedia("(orientation: portrait)").matches
  );

  // Fetch classes by school ID and then class assessments
  useEffect(() => {
    if (schoolid) {
      getClassesBySchoolId(schoolid).then((response) => {
        setClasses(response);
        const ids = response.map((classItem) => classItem.id);
        setClassIds(ids);
      });
    }
  }, [schoolid]);

  // Fetch class assessments after classIds is populated
  useEffect(() => {
    if (classIds.length > 0) {
      getClassAssessmentsForMultipleClasses(classIds).then((response) => {
        setAllClassesAssessments(response);
      });
    }
  }, [classIds]); // Updated to depend on classIds, ensuring it's populated before calling the API

  // Orientation change handler
  useEffect(() => {
    const handleOrientationChange = () => {
      setIsPortrait(window.innerWidth < window.innerHeight);
    };

    window.addEventListener("resize", handleOrientationChange);

    return () => {
      window.removeEventListener("resize", handleOrientationChange);
    };
  }, []);

  useEffect(() => {
    window.HubSpotConversations?.widget?.refresh();
  }, []);

  // Separate assessments into two categories while keeping the same structure
  const gradeLevel1Assessments = [];
  const gradeLevel2Assessments = [];

  allClassesAssessments.forEach((classAssessment) => {
    const newAssessmentData = {
      classId: classAssessment.classId,
      assessments: {},
    };

    Object.entries(classAssessment.assessments).forEach(
      ([assessmentId, assessment]) => {
        if (assessment.grade_level1) {
          newAssessmentData.assessments[assessmentId] = assessment; // Maintain the same structure
        }
        if (assessment.grade_level2) {
          newAssessmentData.assessments[assessmentId] = assessment; // Maintain the same structure
        }
      }
    );

    // Only push if there are assessments in the respective category
    if (Object.keys(newAssessmentData.assessments).length > 0) {
      if (
        newAssessmentData.assessments[
          Object.keys(newAssessmentData.assessments)[0]
        ].grade_level1
      ) {
        gradeLevel1Assessments.push(newAssessmentData);
      }
      if (
        newAssessmentData.assessments[
          Object.keys(newAssessmentData.assessments)[0]
        ].grade_level2
      ) {
        gradeLevel2Assessments.push(newAssessmentData);
      }
    }
  });

  return (
    <Container fluid className="vh-100">
      <HeadingBanner
        name={name}
        isLoggedIn={true}
        cloud={true}
        teacherid={teacherid}
      />
      <Row className="h-100">
        <LeftPanel />
        <Col sm={{ span: 10, offset: 2 }} className="blue-background">
          <SencoResultClassOverview
            teachers={teachers}
            gradeLevel={gradeLevel}
            name={name}
            teacherid={teacherid}
            schoolid={schoolid}
            allClassesAssessments={allClassesAssessments}
            classIds={classIds}
            gradeLevel1Assessments={gradeLevel1Assessments} // Pass grade level 1 assessments
            gradeLevel2Assessments={gradeLevel2Assessments} // Pass grade level 2 assessments
          />
        </Col>
      </Row>
      {isPortrait && <OrientationPrompt />}
    </Container>
  );
}

export default SencoResultDetail;
