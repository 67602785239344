import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend
);

const CandyBearAnalysisGraph = ({
  selectedChild,
  setSelectedChildID,
  individualStudentResult,
}) => {
  const [taskData, setTaskData] = useState({});
  const [assessmentNames, setAssessmentNames] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (selectedChild?.student?.id) {
      setSelectedChildID(selectedChild.student.id);
    }
  }, [selectedChild]);

  // Sort assessments based on keywords in the name
  const sortAssessments = (assessments) => {
    const order = { Baseline: 1, Midpoint: 2, End: 3 };
    return [...assessments].sort((a, b) => {
      const aOrder =
        Object.entries(order).find(([key]) => a.name.includes(key))?.[1] || 4;
      const bOrder =
        Object.entries(order).find(([key]) => b.name.includes(key))?.[1] || 4;
      return aOrder - bOrder;
    });
  };

  useEffect(() => {
    if (individualStudentResult && individualStudentResult.length > 0) {
      setLoading(false);

      const sortedResults = sortAssessments(individualStudentResult);

      const tasksByName = {};
      const names = [];

      sortedResults.forEach((assessment, index) => {
        names.push(assessment.name);

        if (assessment.tasks && assessment.tasks.length > 0) {
          assessment.tasks.forEach((task) => {
            if (!tasksByName[task.name]) {
              tasksByName[task.name] = new Array(sortedResults.length).fill(0);
            }
            tasksByName[task.name][index] = Math.round(task.standardScore || 0);
          });
        } else {
          Object.keys(tasksByName).forEach((taskName) => {
            tasksByName[taskName][index] = 0;
          });
        }
      });

      setTaskData(tasksByName);
      setAssessmentNames(names);
    } else {
      setLoading(true);
      setTaskData({});
      setAssessmentNames([]);
    }
  }, [individualStudentResult]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const colors = [
    "rgba(54, 162, 235, 0.2)",
    "rgba(255, 165, 0, 0.6)",
    "rgba(144, 238, 144, 0.6)",
  ];
  const borderColors = [
    "rgba(54, 162, 235, 1)",
    "rgba(255, 159, 64, 1)",
    "rgba(75, 192, 192, 1)",
  ];

  const taskNames = Object.keys(taskData);
  const datasets = assessmentNames.map((name, index) => ({
    label: name,
    data: taskNames.map((taskName) => taskData[taskName][index]),
    backgroundColor: colors[index % colors.length],
    borderColor: borderColors[index % borderColors.length],
    borderWidth: 1,
  }));

  const data = {
    labels: taskNames,
    datasets,
  };

  const options = {
    responsive: true,
    indexAxis: "x",
    scales: {
      x: {
        title: {
          display: true,
          text: "Tasks",
          font: {
            size: 16,
          },
        },
        ticks: {
          font: {
            size: 14,
          },
        },
        stacked: false,
      },
      y: {
        title: {
          display: true,
          text: "Scores",
          font: {
            size: 16,
          },
        },
        ticks: {
          stepSize: 10,
          font: {
            size: 14,
          },
          min: 0,
          max: 140,
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            return `${context.dataset.label} - ${Math.round(context.raw)}`;
          },
        },
      },
      title: {
        display: true,
        text: `${selectedChild.student.name} Analysis - Progress Between Testing Points - Standard Scores`,
        font: {
          size: 18,
        },
        padding: {
          bottom: 20,
        },
      },
    },
  };

  return (
    <div>
      <Bar data={data} options={options} />
    </div>
  );
};

export default CandyBearAnalysisGraph;
