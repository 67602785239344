import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(ArcElement, Tooltip, Legend, Title, ChartDataLabels);

function PieThresholdGraph({
  assessmentResults,
  thresholds,
  fromSencoResults,
}) {
  const getColorForTask = (
    taskName,
    averageScore,
    belowThresholdPercentage
  ) => {
    const taskThreshold = thresholds[taskName]?.task;
    const studentThreshold = thresholds[taskName]?.student;

    if (taskThreshold === undefined || studentThreshold === undefined) {
      return "gray";
    }

    if (
      averageScore < taskThreshold &&
      belowThresholdPercentage >= studentThreshold
    ) {
      return "red";
    }

    if (
      (averageScore >= taskThreshold && averageScore <= taskThreshold + 10) ||
      (averageScore >= studentThreshold - 10 &&
        averageScore <= studentThreshold)
    ) {
      return "orange";
    }

    return "green";
  };

  const performanceMessage = (score) => {
    if (score >= 80)
      return "Excellent performance - Certain children may need support or possible team teaching warranted with extension for those more capable.";
    if (score >= 60)
      return "Very good performance - Certain children may need support or possible team teaching warranted.";
    if (score >= 40)
      return "Good performance - Certain children may need support or possible team teaching warranted.";
    if (score >= 20) return "Fair performance - May add value.";
    return "Poor performance - Needed.";
  };

  const taskNames = Object.keys(thresholds);

  const averageScores = taskNames.map((taskName) => {
    const scores = assessmentResults
      .map(
        (student) => student.tasks.find((task) => task.name === taskName)?.score
      )
      .filter((score) => score !== undefined);

    if (scores.length === 0) return 0;

    const average =
      scores.reduce((acc, score) => acc + score, 0) / scores.length;
    const roundedAverage = Math.round(average * 100);

    return roundedAverage;
  });

  const belowThresholdCounts = taskNames.map((taskName) => {
    const threshold = thresholds[taskName]?.task;
    if (threshold === undefined) return 0;

    const countBelowThreshold = assessmentResults
      .map(
        (student) =>
          student.tasks.filter(
            (task) => task.name === taskName && task.score < threshold
          ).length
      )
      .reduce((acc, count) => acc + count, 0);

    const percentageBelowThreshold =
      (countBelowThreshold / assessmentResults.length) * 100;

    return percentageBelowThreshold;
  });

  const filteredTaskNames = taskNames.filter(
    (_, index) => !isNaN(averageScores[index]) && averageScores[index] > 0
  );
  const filteredAverageScores = averageScores.filter(
    (score) => !isNaN(score) && score > 0
  );
  const filteredBelowThresholdCounts = belowThresholdCounts.filter(
    (count) => !isNaN(count) && count > 0
  );

  const filteredColors = filteredTaskNames.map((taskName, index) =>
    getColorForTask(
      taskName,
      filteredAverageScores[index],
      filteredBelowThresholdCounts[index]
    )
  );

  const pieData = {
    labels: filteredTaskNames,
    datasets: [
      {
        data: filteredAverageScores,
        backgroundColor: filteredColors,
      },
    ],
  };

  const pieOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            return `${context.label}: ${context.raw}%`;
          },
        },
      },
      datalabels: {
        color: "white",
        font: {
          size: 12,
          weight: "bold",
        },
        formatter: (value, context) => {
          const taskName = context.chart.data.labels[context.dataIndex];
          return `${taskName}\n${value}%`;
        },
        anchor: "center",
        align: "center",
        padding: 5,
      },
    },
  };

  const legendItems = [
    {
      color: "red",
      label:
        "High likelihood - Indicates areas with significant need for attention",
    },
    {
      color: "orange",
      label:
        "Moderate likelihood - Indicates areas with moderate need for attention",
    },
    {
      color: "green",
      label: "No likelihood - Indicates areas within acceptable norms",
    },
  ];

  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    position: "relative",
    padding: "20px", // Add padding around the container
  };

  const titleStyle = {
    marginBottom: "10px",
    fontSize: "16px",
    fontWeight: "bold",
  };

  const legendStyle = {
    backgroundColor: "white",
    padding: "5px",
    borderRadius: "5px",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
    fontSize: "12px",
    marginBottom: "20px", // Increase space between legend and title
  };

  const textboxStyle = {
    backgroundColor: "white",
    padding: "10px",
    borderRadius: "5px",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
    fontSize: "12px",
    maxWidth: "250px",
    textAlign: "left", // Ensure text is aligned left
    marginBottom: "20px", // Space between textbox and recommendations
    alignSelf: "flex-start", // Align itself to the start of its container
  };

  const recommendationsStyle = {
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    fontSize: "16px",
    maxWidth: "600px",
    textAlign: "center",
    margin: "20px 0", // Center horizontally with margin and add space
    lineHeight: "1.5",
  };

  const highlightStyle = {
    backgroundColor: "#FFFFE0", // Very light yellow background
    fontWeight: "bold", // Optional: makes the text bold
    padding: "2px 4px", // Optional: adds padding inside the highlight
    borderRadius: "4px", // Optional: adds a slight border radius
  };

  const performanceMessages = filteredTaskNames.map((taskName, index) => {
    const averageScore = filteredAverageScores[index];
    const formattedScore = averageScore.toFixed(1);
    return (
      <span key={taskName}>
        <span style={highlightStyle}>{taskName}</span> was{" "}
        {performanceMessage(averageScore).split(" - ")[0].toLowerCase()} with an
        average score of <span style={highlightStyle}>{formattedScore}%</span>.
      </span>
    );
  });

  const summaryMessage = (
    <div>
      {performanceMessages.length > 0
        ? performanceMessages.reduce((prev, curr) => [prev, " ", curr])
        : "No data available."}
      . Further information is available about this below.
    </div>
  );

  return (
    <div style={containerStyle}>
      <div style={legendStyle}>
        {legendItems.map((item, index) => (
          <div
            key={index}
            style={{
              marginBottom: "5px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "10px",
                height: "10px",
                backgroundColor: item.color,
                marginRight: "5px",
                borderRadius: "3px",
              }}
            />
            <span>{item.label}</span>
          </div>
        ))}
      </div>
      <div style={titleStyle}>
        Need for Whole Class Testing Based on Subtest Areas
      </div>
      <div style={{ width: "40%", height: "40%" }}>
        <Pie data={pieData} options={pieOptions} />
      </div>
      <hr />
      <div style={textboxStyle}>
        Based on the class performance in the test, ALPACA can determine from
        the data what areas are likely to require further whole class teaching
        based on the number of students who performed below a certain level in
        each subtest area.
      </div>
      <hr />
      <div style={recommendationsStyle}>{summaryMessage}</div>
    </div>
  );
}

export default PieThresholdGraph;
