import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import "./ClassOverview.css";

import ResultsStatus from "./ResultsStatus";
import ReportsButton from "./ReportsButton";
import ClassStudents from "./ClassStudents";
import ReturnToResults from "./ReturnToResults";
import GradeCard from "./GradeCard";

import SencoReportsButton from "./SencoReportsButton";
import JiSiReportsButton from "./JiSiReportsButton";

import {
  getStudentsForClass,
  getClassAssessments,
  getStudentResults,
  getResultsByStudent,
} from "../services/APIClient";

function ReportsOverview(props) {
  const {
    assessment,
    classDetails,
    gradeLevel,
    name,
    teachers,
    teacherid,
    schoolid,
    students,
  } = props;
  const [classAssessments, setClassAssessments] = useState([]);
  const [assessmentResultsByStudent, setAssessmentResultsByStudent] = useState(
    []
  );
  const [studentsReadingScore, setStudentsReadingScore] = useState([]);
  const classAssessmentsValues = Object.values(classAssessments);

  // Find the teacher with the matching ID
  const matchingTeacher = teachers.find((teacher) => teacher.id === teacherid);
  const isSENCO = matchingTeacher?.additionalRoles?.includes("SENCO");

  console.log(isSENCO, "MT");

  useEffect(() => {
    getClassAssessments(classDetails.id).then((response) => {
      setClassAssessments(response);
    });
  }, [classDetails.id]);

  useEffect(() => {
    const fetchAllAssessmentResults = () => {
      if (students && students.length > 0) {
        const assessmentInstanceIds = students.reduce((acc, student) => {
          const ids = student.assessments
            ? student.assessments.map((assessment) => assessment.id)
            : [];
          return [...acc, ...ids];
        }, []);

        if (assessmentInstanceIds.length > 0) {
          getResultsByStudent(assessmentInstanceIds)
            .then((response) => {
              setAssessmentResultsByStudent(response);
            })
            .catch((error) => {
              console.error("Error fetching assessment results:", error);
            });
        }
      }
    };

    fetchAllAssessmentResults();
  }, [students]);

  const groupByStudentId = (results) => {
    return results.reduce((acc, result) => {
      const studentId = result.student.id;
      const studentName = result.student.name;
      if (!acc[studentId]) {
        acc[studentId] = { name: studentName, assessments: [] };
      }
      acc[studentId].assessments.push(result);
      return acc;
    }, {});
  };

  const calculateReadingScores = (groupedResults) => {
    const scores = [];
    Object.keys(groupedResults).forEach((studentId) => {
      const { name, assessments } = groupedResults[studentId];
      let totalScore = 0;
      let taskCount = 0;

      assessments.forEach((assessment) => {
        assessment.tasks.forEach((task) => {
          if (task.score !== undefined) {
            totalScore += task.score;
            taskCount++;
          }
        });
      });

      scores.push({
        studentName: name,
        studentId: studentId,
        readingScore:
          taskCount > 0
            ? Math.round((totalScore / taskCount) * 100)
            : "No completed tasks",
      });
    });
    return scores;
  };

  const mergeStudentsWithScores = (students, scores) => {
    const scoresMap = scores.reduce((map, score) => {
      map[score.studentId] = score.readingScore;
      return map;
    }, {});

    return students.map((student) => ({
      ...student,
      readingScore: scoresMap[student.id] || null,
    }));
  };

  useEffect(() => {
    if (assessmentResultsByStudent.length > 0) {
      const groupedResults = groupByStudentId(assessmentResultsByStudent);
      const readingScores = calculateReadingScores(groupedResults);
      const mergedStudents = mergeStudentsWithScores(students, readingScores);
      setStudentsReadingScore(mergedStudents);
    }
  }, [assessmentResultsByStudent, students]);

  return (
    <div className="class-overview-container">
      <Container className="container-shape">
        <Row className="page-description-bar">
          <Col className="col-7">
            <Row className="inline-block">
              <Col className="col-6">
                <h2 className="page-description-header">Reports</h2>
              </Col>
              <Col className="col-3">
                <GradeCard
                  gradeLevel={gradeLevel}
                  gradeLevel1={classDetails.grade_level1}
                  gradeLevel2={classDetails.grade_level2}
                  trialClass={classDetails.trial_class}
                />
              </Col>
            </Row>
            <p className="paragraph-font-size">
              Select the type of report you would like to see.
            </p>
          </Col>
        </Row>
        <hr />
        <Row>
          {studentsReadingScore.length > 0 ? (
            <>
              {isSENCO && (
                <SencoReportsButton
                  students={students}
                  assessment={assessment}
                  classAssessmentsValues={classAssessmentsValues}
                  classDetails={classDetails}
                  teachers={teachers}
                  teacherid={teacherid}
                  studentsReadingScore={studentsReadingScore}
                  schoolid={schoolid}
                />
              )}
              {classDetails.grade_level1 && (
                <JiSiReportsButton
                  students={students}
                  assessment={assessment}
                  classAssessmentsValues={classAssessmentsValues}
                  classDetails={classDetails}
                  teachers={teachers}
                  teacherid={teacherid}
                  studentsReadingScore={studentsReadingScore}
                  schoolid={schoolid}
                />
              )}
            </>
          ) : (
            <div>Loading Reports...</div>
          )}
        </Row>

        <br />
        <Row>
          <Col>
            <ReturnToResults
              classDetails={classDetails}
              teachers={teachers}
              classAssessments={classAssessments}
              schoolid={schoolid}
              teacherid={teacherid}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ReportsOverview;
