import React from "react";
import { useNavigate } from "react-router-dom";
import {
  editClass,
  updateClassOwnerId,
  createClassOwnershipHistory,
} from "../services/APIClient";
import "./SaveClassDetails.css";

function SaveClassDetails(props) {
  const {
    classDetails,
    teachers,
    newClassName,
    gradeLevel,
    newClassTeacherId,
    schoolid,
    className,
    teacherid,
    selectedReason,
    classOwnerId,
  } = props;
  const classid = classDetails.id;
  const navigate = useNavigate();

  console.log(schoolid, "SID");

  const classOwnershipHistoryData = {
    teacherId: teacherid,
    oldClassOwner: classOwnerId,
    newClassOwner: newClassTeacherId,
    reasonForChangeId: selectedReason.id,
    classownerchangereason: selectedReason.reason,
    dateChanged: new Date().toISOString(),
  };

  const handleClassEdit = async (e) => {
    e.preventDefault();

    try {
      // Update class details
      console.log(className);

      const classToUpdate =
        newClassName.trim() !== "" ? newClassName : className;

      await editClass(classid, classToUpdate, gradeLevel);

      // Update class owner ID if a new teacher is selected
      if (newClassTeacherId) {
        await updateClassOwnerId(classid, newClassTeacherId);
        await createClassOwnershipHistory(classOwnershipHistoryData);
      }

      // Redirect after saving
      navigate(`/class/detail/`, {
        state: {
          classDetails: { classDetails },
          teachers: { teachers },
          name: { newClassName },
          gradeLevel: { gradeLevel },
          schoolid: { schoolid },
          forceRefreshKey: Date.now(),
        },
      });
    } catch (error) {
      console.error("Error saving class details:", error);
    }
  };

  return (
    <button
      onClick={handleClassEdit}
      className="green-button"
      style={{
        border: "1px solid #16971b",
      }}
    >
      Save
    </button>
  );
}

export default SaveClassDetails;
