import React, { useState, useEffect } from "react";
import { withRouter } from "../utils/withRouter.js";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Form from "react-bootstrap/Form";
import {
  createClass,
  getSchools,
  getCountryGrades,
  getTeachers,
} from "../services/APIClient.js";
import SimpleNavigationButton from "./SimpleNavigationButton.js";
import ConfirmClassOwnerModal from "./ConfirmClassOwnerModal";

function CreateSpecialEdClassForm(props) {
  const [classTitle, setClassTitle] = useState("");
  const [classTitleError, setClassTitleError] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [gradeLevelOptions, setGradeLevelOptions] = useState([]);
  const [teacherOptions, setTeacherOptions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [classOwner, setClassOwner] = useState(null);

  const grade1Name = gradeLevelOptions[0]?.grade1 || "Special Ed";

  const submitButtonRef = React.createRef();

  const teacherId = props.teacherid;

  const schoolid = props.router.location.state.schoolid.schoolid;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const schoolsResponse = await getSchools();
        const matchingSchool = schoolsResponse.data.find(
          (school) => school.id === schoolid
        );

        if (matchingSchool) {
          const countryid = matchingSchool.countryid;

          const gradesResponse = await getCountryGrades(countryid);
          setGradeLevelOptions([gradesResponse]);

          const teachersResponse = await getTeachers(schoolid);
          setTeacherOptions(teachersResponse);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [schoolid]);

  useEffect(() => {
    if (submitted) {
      const isClassTitleValid = classTitle.length > 0;
      setClassTitleError(isClassTitleValid ? "" : "Please enter a class name.");
    }
  }, [classTitle, submitted]);

  function handleSubmit(event) {
    event.preventDefault();
    setSubmitted(true);

    const isClassTitleValid = classTitle.length > 0;

    if (!isClassTitleValid) {
      setClassTitleError("Please enter a class name.");
      return;
    }

    setShowModal(true);
  }

  function handleModalConfirm(selectedTeacherId) {
    const formattedClassTitle = `24-SpecialEd-${classTitle}`;
    const grade = "Special Ed";
    const trialClass = false;
    const specialEdClass = true;
    const setClass = false;

    const finalClassOwner = selectedTeacherId || teacherId;

    createClass(
      formattedClassTitle,
      grade,
      grade === "Special Ed",
      false,
      trialClass,
      specialEdClass,
      finalClassOwner,
      setClass
    )
      .then(() => {
        setTimeout(() => {
          props.router.navigate("/classadmindashboard");
        }, 3000); // Delay for 3 seconds before navigating
      })
      .catch((error) => {
        console.error("Error creating class:", error);
      });

    setShowModal(false);
  }

  function handleModalClose() {
    setShowModal(false);
  }

  function handleSelectOwner(teacherId) {
    setClassOwner(teacherId);
  }

  return (
    <>
      <Form className="form form-detail" onSubmit={handleSubmit}>
        <Form.Group as={Row} className="mb-3" controlId="class-name">
          <Form.Label className="create-class-label" column sm="2">
            Class Name
          </Form.Label>
          <Col sm="10">
            <Form.Control
              placeholder="Special Ed Class ABC..."
              id="classname-form-input"
              size="md"
              type="text"
              value={`24-SpecialEd-${classTitle}`}
              onChange={(e) => setClassTitle(e.target.value.slice(13))}
              isInvalid={submitted && !!classTitleError}
            />
            <Form.Control.Feedback type="invalid">
              {classTitleError}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="grade">
          <Form.Label className="create-class-label" column sm="2">
            Class Level
          </Form.Label>
          <Col>
            <Form.Control
              as="select"
              id="grade-form-input"
              size="md"
              value="Grade 1"
              disabled
            >
              <option value="Grade 1">{grade1Name}</option>
            </Form.Control>
          </Col>
        </Form.Group>
        <div className="float-right">
          <SimpleNavigationButton
            path="/classadmindashboard"
            label="Cancel"
            colour="white"
          />
          <button className="green-button" type="submit" ref={submitButtonRef}>
            Create
          </button>
        </div>
      </Form>

      <ConfirmClassOwnerModal
        show={showModal}
        onConfirm={handleModalConfirm}
        onClose={handleModalClose}
        teacherOptions={teacherOptions}
        onSelectOwner={handleSelectOwner}
      />
    </>
  );
}

export default withRouter(CreateSpecialEdClassForm);
