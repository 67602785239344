import React, { useState, useMemo, useEffect, useRef } from "react";
import { Table } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ReactToPrint from "react-to-print";
import secondsToTime from "../services/secondsToTime";

function SencoStudentsResultsTable(props) {
  const {
    classesResults,
    toggleScoreType,
    showAtRiskOnly,
    schoolid,
    teacherid,
    setShowAtRiskOnly,
    classId,
    assessment,
    classDetails,
    gradeLevel,
    teachers,
    assessmentResultsByStudent,
    fromSencoResults,
    classAssessments,
    ref,
  } = props;

  let navigate = useNavigate();

  const toIndividualStudentResults = (student) => {
    navigate("/student/results", {
      state: {
        classDetails: { classDetails },
        student: { student },
        schoolid: schoolid,
        teacherid: teacherid,
        gradeLevel: { gradeLevel },
        classAssessments: { classAssessments },
        teachers: { teachers },
        assessment: { assessment },
        taskNameArray: { taskNames },
        assessmentResultsByStudent: { assessmentResultsByStudent },
        toggleScoreType: { toggleScoreType },
        fromSencoResults: { fromSencoResults },
      },
    });
  };

  const [scoreArray, setScoreArray] = useState([]);

  const [sortCriteria, setSortCriteria] = useState({
    field: "name",
    order: "asc",
  });

  const scoreRetriever = () => {
    if (!classesResults || typeof classesResults !== "object") {
      console.error("classesResults should be an object", classesResults);
      return;
    }

    let newScoreArray = [];

    Object.values(classesResults).forEach((classObj) => {
      const students = classObj.students;

      if (!students || typeof students !== "object") return;

      const studentIds = Object.keys(students);

      studentIds.forEach((studentId) => {
        const student = students[studentId];
        const studentName = student.name;
        const studentID = studentId;
        const studentResults = student.results || [];
        let totalScore = 0;
        let allScores = [];
        let tasksArray = [];
        let totalDuration = 0;

        studentResults.forEach((result) => {
          const studentTasks = result.tasks;

          if (studentTasks && studentTasks.length > 0) {
            studentTasks.sort((a, b) => a.order - b.order);
            studentTasks.forEach((task) => {
              totalScore += task.score || 0;
              let score;

              totalDuration += task.duration || 0;

              if (toggleScoreType) {
                score = task.groupPercentile;
                allScores.push(score?.toFixed());
              } else {
                score = task.score;
                allScores.push(Math.round(score * 100));
              }

              tasksArray.push({
                [task.name]: score
                  ? toggleScoreType
                    ? score.toFixed()
                    : Math.round(score * 100)
                  : -1,
              });
            });
          }
        });

        newScoreArray.push({
          name: studentName,
          id: studentID,
          totalScore: totalScore,
          scores: allScores,
          taskScores: tasksArray,
          readingScore:
            totalScore > 0
              ? Math.round((totalScore / tasksArray.length) * 100)
              : 0,
          time: totalDuration,
        });
      });
    });

    setScoreArray(newScoreArray);
  };

  useEffect(() => {
    scoreRetriever();
  }, [classesResults, toggleScoreType]);

  const renderTaskScore = (taskScores, taskName) => {
    if (!taskScores || taskScores.length === 0) {
      return "Not Started";
    }

    const taskScoreEntry = taskScores.find(
      (scoreEntry) => scoreEntry[taskName] !== undefined
    );

    if (!taskScoreEntry) return "Not Started";

    const score = taskScoreEntry[taskName];
    let scoreClass = "";
    let scoreDisplay = toggleScoreType ? score : `${score}%`;

    if (score === 0) {
      scoreClass = "low-color";
    } else if (score > 0 && score <= 20) {
      scoreClass = "low-color";
    } else if (score > 20 && score <= 40) {
      scoreClass = "med-color";
    } else if (score > 40 && score <= 80) {
      scoreClass = "noRisk-color";
    } else if (score > 80 && score <= 100) {
      scoreClass = "high-color";
    }

    return <span className={`task-score ${scoreClass}`}>{scoreDisplay}</span>;
  };

  const handleAtRiskFilterChange = (e) => {
    setShowAtRiskOnly(e.target.checked);
  };

  const renderAtRiskFlag = (readingScore) => {
    if (readingScore < 20) {
      return (
        <img
          src={process.env.PUBLIC_URL + "/assets/imgs/risk-flag-red.png"}
          alt="Red Flag"
          className="flag-size"
        />
      );
    } else if (readingScore >= 20 && readingScore <= 40) {
      return (
        <img
          src={process.env.PUBLIC_URL + "/assets/imgs/risk-flag-yellow.png"}
          alt="Yellow Flag"
          className="flag-size"
        />
      );
    }
    return null;
  };

  const getReadingScoreClass = (score) => {
    if (score === 0) {
      return "low-color";
    } else if (score > 0 && score <= 20) {
      return "low-color";
    } else if (score > 20 && score <= 40) {
      return "med-color";
    } else if (score > 40 && score <= 100) {
      return "noRisk-color";
    }
    return "";
  };

  const taskNames = useMemo(() => {
    const names = new Set();

    Object.values(classesResults).forEach((classObj) => {
      const students = classObj.students || {};
      Object.values(students).forEach((student) => {
        student.results?.forEach((result) => {
          result.tasks.forEach((task) => {
            names.add(task.name);
          });
        });
      });
    });

    return Array.from(names);
  }, [classesResults]);

  const sortedStudentArray = useMemo(() => {
    return [...scoreArray].sort((a, b) => {
      const field = sortCriteria.field;
      const order = sortCriteria.order;

      if (field === "name") {
        return order === "asc"
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name);
      }

      if (field === "totalScore") {
        return order === "asc"
          ? a.totalScore - b.totalScore
          : b.totalScore - a.totalScore;
      }

      return 0;
    });
  }, [scoreArray, sortCriteria]);

  const handleSort = (field) => {
    setSortCriteria((prev) => {
      const isAscending = prev.field === field && prev.order === "asc";
      return { field, order: isAscending ? "desc" : "asc" };
    });
  };

  // Reference to the table element for printing
  const componentRef = useRef();

  return (
    <Row>
      <Col>
        <div>
          <ReactToPrint
            trigger={() => <button className="blue-button">Print</button>}
            content={() => componentRef.current}
          />
          <div ref={componentRef}>
            <div ref={ref}>
              <Table className="custom-table">
                <thead className="table-header">
                  <tr>
                    <th onClick={() => handleSort("name")}>
                      <div className="header-content">
                        Student Name
                        <img
                          className="sort-icon"
                          src={
                            sortCriteria.field === "name" &&
                            sortCriteria.order === "asc"
                              ? process.env.PUBLIC_URL +
                                "/assets/imgs/desc-icon-active.png"
                              : process.env.PUBLIC_URL +
                                "/assets/imgs/asc-icon-default.png"
                          }
                          alt="Sort"
                        />
                      </div>
                    </th>
                    <th>Average Score across all subtests</th>
                    <th>At-Risk</th>

                    {taskNames.map((taskName) => (
                      <th key={taskName} onClick={() => handleSort(taskName)}>
                        <div className="header-content">
                          {taskName}
                          <img
                            className="sort-icon"
                            src={
                              sortCriteria.field === taskName &&
                              sortCriteria.order === "asc"
                                ? process.env.PUBLIC_URL +
                                  "/assets/imgs/desc-icon-active.png"
                                : process.env.PUBLIC_URL +
                                  "/assets/imgs/asc-icon-default.png"
                            }
                            alt="Sort"
                          />
                        </div>
                      </th>
                    ))}
                    <th>Time</th>
                    <th className="header-th header-size-results">
                      <div className="header-content">View</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {sortedStudentArray
                    .filter((student) => {
                      return !showAtRiskOnly || student.readingScore <= 40;
                    })
                    .map((student) => (
                      <tr key={student.id}>
                        <td onClick={() => toIndividualStudentResults(student)}>
                          {student.name}
                        </td>
                        <td>
                          <span
                            className={`reading-score ${getReadingScoreClass(
                              student.readingScore
                            )}`}
                          >
                            {student.readingScore}%
                          </span>
                        </td>
                        <td>{renderAtRiskFlag(student.readingScore)}</td>

                        {taskNames.map((taskName) => (
                          <td key={taskName}>
                            {renderTaskScore(student.taskScores, taskName)}
                          </td>
                        ))}
                        <td>
                          {student.time
                            ? secondsToTime(student.time)
                            : "No time recorded"}
                        </td>
                        <td className="centre-student-items">
                          <img
                            src={
                              process.env.PUBLIC_URL + "/assets/imgs/eyeCon.png"
                            }
                            onClick={() => {
                              toIndividualStudentResults(student);
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default SencoStudentsResultsTable;
