import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Accordion from "react-bootstrap/Accordion";

import "./ClassOverview.css";

import ResultsStatus from "./ResultsStatus";
import ReportsButton from "./ReportsButton";
import ClassStudents from "./ClassStudents";
import SimpleNavigatioButton from "./SimpleNavigationButton";
import GradeCard from "./GradeCard";

import {
  getClassAssessments,
  getAssessmentInstancesByStudentIds,
  getMultipleClassAssessments,
} from "../services/APIClient";

function SetResultClassOverview(props) {
  // Class Personnel
  let classDetails = props.classDetails;
  let classid = classDetails.id;
  let gradeLevel = props.gradeLevel;
  let teachers = props.teachers;
  let teacherid = props.teacherid;
  let students = props.students;
  let additionalStudents = props.additionalStudents; // Additional students passed as a prop
  let gradeLevel1 = classDetails.grade_level1;
  let gradeLevel2 = classDetails.grade_level2;
  let trialClass = classDetails.trial_class;
  let schoolid = props.schoolid;

  const [classAssessments, setClassAssessments] = useState([]);
  const [gradeLevel1Assessments, setGradeLevel1Assessments] = useState([]);
  const [gradeLevel2Assessments, setGradeLevel2Assessments] = useState([]);
  const [multipleClassAssessments, setMultipleClassAssessments] = useState([]);
  const [assessmentInstancesData, setAssessmentInstancesData] = useState([]); // Updated variable name

  const classAssessmentsValues = Object.values(classAssessments);

  console.log(assessmentInstancesData, "NEW");

  const transformAssessmentsData = (response) => {
    // Extract the assessments from the response
    const assessmentsArray = Object.values(response[0].assessments);

    console.log("Transformed Assessments Data:", assessmentsArray);

    // You can return this array or set it in state
    return assessmentsArray; // Return the transformed array
  };

  const transformAssessmentInstances = (response) => {
    const groupedData = {};

    response.forEach((instance) => {
      const { assessmentid } = instance;

      // Initialize the group if it doesn't exist
      if (!groupedData[assessmentid]) {
        groupedData[assessmentid] = {
          grade_level1: null, // This can be modified based on logic if available
          grade_level2: null, // This can be modified based on logic if available
          name: instance.name,
          completed: instance.completed ? 1 : 0,
          pending: instance.completed ? 0 : 1,
          instances: [],
        };
      }

      // Add the instance id to the corresponding assessment group
      groupedData[assessmentid].instances.push(instance.id);

      // Update the completed and pending count based on the current instance
      if (instance.completed) {
        groupedData[assessmentid].completed += 1;
      } else {
        groupedData[assessmentid].pending += 1;
      }
    });

    // Convert the grouped object back to an array for easier rendering
    return Object.values(groupedData);
  };

  useEffect(() => {
    // Fetch assessments for the main class
    getClassAssessments(classid).then((response) => {
      console.log(response);
      setClassAssessments(response);
    });
  }, [classid]);

  useEffect(() => {
    const studentIds = students.map((student) => student.id);
    console.log(studentIds, "IDS");

    getAssessmentInstancesByStudentIds(studentIds).then((response) => {
      console.log(response, "INSTANCES");

      // Transform the fetched instances into the new format
      const transformedData = transformAssessmentInstances(response);
      setAssessmentInstancesData(transformedData); // Use the new variable
    });
  }, [students]);

  useEffect(() => {
    if (students && students.length > 0) {
      const classIds = students.map((student) => student.classId);
      console.log("Class IDs extracted from students:", classIds);

      getMultipleClassAssessments(classIds)
        .then((response) => {
          console.log("Multiple Class Assessments Response:", response);

          // Transform the response to the desired format
          const transformedAssessments = transformAssessmentsData(response);
          // You can store it in state if needed
          setMultipleClassAssessments(transformedAssessments);
        })
        .catch((error) => {
          console.error("Error fetching multiple class assessments:", error);
        });
    }
  }, [students]); // Run this effect when students change

  console.log("classAssessments:", classAssessments);
  console.log("MultipleAssessments:", multipleClassAssessments);
  console.log("Assessment Instances Data:", assessmentInstancesData); // Logging the new variable

  useEffect(() => {
    const gradeLevel1Arr = [];
    const gradeLevel2Arr = [];

    Object.values(multipleClassAssessments).forEach((assessment) => {
      if (!assessment.task) {
        if (assessment.grade_level1) {
          gradeLevel1Arr.push(assessment);
        }
        if (assessment.grade_level2) {
          gradeLevel2Arr.push(assessment);
        }
      }
    });

    setGradeLevel1Assessments(gradeLevel1Arr);
    setGradeLevel2Assessments(gradeLevel2Arr);
  }, [multipleClassAssessments]);

  console.log("gradeLevel1Assessments:", gradeLevel1Assessments);
  console.log("gradeLevel2Assessments:", gradeLevel2Assessments);

  const prefix = gradeLevel1Assessments[0]?.name.split(" ")[0];

  return (
    <div className="class-overview-container">
      <Container className="container-shape">
        <Row className="page-description-bar">
          <Col className="col-7">
            <Row className="inline-block">
              <Col className="col-6">
                <h2 className="page-description-header">Results Overview</h2>
              </Col>
              <Col className="col-3">
                <GradeCard
                  gradeLevel={gradeLevel}
                  gradeLevel1={gradeLevel1}
                  gradeLevel2={gradeLevel2}
                  trialClass={trialClass}
                />
              </Col>
            </Row>
            <p className="paragraph-font-size">
              Select the assessment that you would like to see the results for.
            </p>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            {gradeLevel1Assessments.length > 0 ? (
              gradeLevel2 ? (
                <Accordion className="results-custom-accordion">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <strong>{prefix} Screener Results</strong>
                    </Accordion.Header>
                    <Accordion.Body>
                      <ResultsStatus
                        classAssessmentsValues={gradeLevel1Assessments}
                        classDetails={classDetails}
                        teachers={teachers}
                        schoolid={schoolid}
                        teacherid={teacherid}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              ) : (
                <ResultsStatus
                  classAssessmentsValues={assessmentInstancesData}
                  classDetails={classDetails}
                  teachers={teachers}
                  schoolid={schoolid}
                  teacherid={teacherid}
                />
              )
            ) : null}
            {gradeLevel2Assessments.length > 0 ? (
              <ResultsStatus
                classAssessmentsValues={gradeLevel2Assessments}
                classDetails={classDetails}
                teachers={teachers}
                schoolid={schoolid}
                teacherid={teacherid}
              />
            ) : null}
            <br />
            <Col>
              <SimpleNavigatioButton
                path="/resultsdashboard"
                label="Back"
                colour="blue"
              />
            </Col>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default SetResultClassOverview;
