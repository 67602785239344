import React, { useRef } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

import ReactToPrint from "react-to-print";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale
);

const SencoTaskScoreGraph = React.forwardRef(
  ({ tasksData, onLabelClick, className }, ref) => {
    // Prepare data for the chart
    const taskNames = Object.keys(tasksData);
    const averageScores = taskNames.map((task) => tasksData[task]);

    const data = {
      labels: taskNames,
      datasets: [
        {
          label: "Average Score",
          data: averageScores,
          fill: false,
          backgroundColor: "rgba(75, 192, 192, 0.7)",
          borderColor: "rgba(75, 192, 192, 1)",
          tension: 0.1,
          pointBackgroundColor: "rgba(75, 192, 192, 1)",
          pointBorderColor: "#fff",
          pointBorderWidth: 2,
          pointRadius: 5,
        },
      ],
    };

    const options = {
      scales: {
        y: {
          beginAtZero: true,
          ticks: {
            stepSize: 10,
            min: 0,
            max: 100,
          },
          title: {
            display: true,
            text: "Score",
          },
        },
        x: {
          title: {
            display: true,
            text: "Task Name",
          },
        },
      },
      plugins: {
        legend: {
          display: true,
        },
      },
    };

    return (
      <div ref={ref}>
        {/* Class Name Section */}
        <h2 className="class-name-print">{className}</h2>
        <Line data={data} options={options} />
      </div>
    );
  }
);

const SencoTaskScoreGraphContainer = ({
  tasksData,
  onLabelClick,
  className,
}) => {
  const componentRef = useRef(); // Create a ref for the component to print

  return (
    <div>
      {/* Print Button */}
      <ReactToPrint
        trigger={() => <button className="blue-button">Print Graph</button>}
        content={() => componentRef.current}
      />

      {/* The graph component */}
      <SencoTaskScoreGraph
        ref={componentRef}
        tasksData={tasksData}
        className={className}
      />

      <div>
        <h5 style={{ textAlign: "center" }}>
          Click a task button below to see the average for a task across all
          classes in the school!
        </h5>
      </div>

      {/* Buttons for each task */}
      <div style={{ marginTop: "20px", textAlign: "center" }}>
        {Object.keys(tasksData).map((task, index) => (
          <button
            key={index}
            onClick={() => onLabelClick(task)}
            style={{ margin: "5px" }}
            className="blue-button"
          >
            {task}
          </button>
        ))}
      </div>
    </div>
  );
};

export default SencoTaskScoreGraphContainer;
