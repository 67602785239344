import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  getStudents,
  getClassesAssessmentResults, // Import the new function
} from "../services/APIClient";
import HeadingBanner from "../components/HeadingBanner";
import LeftPanel from "../components/LeftPanel";
import SencoStudentsResultsOverview from "../components/SencoStudentResultsOverview";
import OrientationPrompt from "../components/OrientationPrompt";
import { Container, Row, Col } from "react-bootstrap";

function SencoStudentsResults(props) {
  const location = useLocation();

  let name = location.state?.name?.name;

  // Handle undefined state
  let classDetails = location.state?.classDetails?.classDetails || {};
  let allClassesAssessments =
    location.state?.allClassesAssessments?.allClassesAssessments;
  let classAssessments =
    location.state?.classAssessments?.classAssessments || [];
  let teachers = location.state?.teachers?.teachers || [];
  let assessment = location.state?.assessment?.assessment || {};
  let assessmentInstances =
    location.state?.assessment?.assessment.instances || [];
  let schoolid = location.state?.schoolid?.schoolid;
  let teacherid = location.state?.teacherid?.teacherid;
  let classid = classDetails.id;
  let classIds = location.state.classIds.classIds; // Keep this to pass to the new function
  let assessmentId = location.state.assessmentId;

  const [studentsResponse, setStudentsResponse] = useState([]);
  const [error, setError] = useState(null);
  const [studentResults, setStudentResults] = useState([]);
  const [showAtRiskOnly, setShowAtRiskOnly] = useState(false);

  const [isPortrait, setIsPortrait] = useState(
    window.matchMedia("(orientation: portrait)").matches
  );

  useEffect(() => {
    // Fetch students for the given class
    if (classid) {
      getStudents(classid)
        .then((response) => {
          setStudentsResponse(response.students || []);
        })
        .catch((err) => {
          console.error("Error fetching students:", err);
          setError("Error fetching students");
        });
    }
  }, [classid]);

  useEffect(() => {
    // Fetch results when classIds and assessmentId are available
    if (classIds.length > 0 && assessmentId) {
      getClassesAssessmentResults(classIds, assessmentId)
        .then((response) => {
          // Process the data to match the structure expected by the overview component
          setStudentResults(response);
        })
        .catch((error) => {
          console.error("Error fetching results:", error);
          setError("Error fetching results");
        });
    }
  }, [classIds, assessmentId]); // Trigger on classIds and assessmentId changes

  return (
    <Container fluid className="vh-100">
      <HeadingBanner
        name={name}
        isLoggedIn={true}
        cloud={true}
        teacherid={teacherid}
      />
      <Row className="h-100">
        <LeftPanel />
        <Col sm={{ span: 10, offset: 2 }} className="blue-background">
          <SencoStudentsResultsOverview
            classesResults={studentResults} // Pass the student results for all classes
            gradeLevel={props.gradeLevel} // Ensure gradeLevel is passed correctly
            teachers={teachers}
            assessment={assessment}
            schoolid={schoolid}
            teacherid={teacherid}
            name={name}
            allClassesAssessments={allClassesAssessments}
            showAtRiskOnly={showAtRiskOnly}
            setShowAtRiskOnly={setShowAtRiskOnly}
            classIds={classIds}
          />
        </Col>
      </Row>
      {isPortrait && <OrientationPrompt />}
      {error && <p className="text-danger">{error}</p>}{" "}
      {/* Display error message if any */}
    </Container>
  );
}

export default SencoStudentsResults;
