import React, { useState, useEffect } from "react";
import { Row, Col, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { getClassOwnerChangeReasons } from "../services/APIClient"; // Import the function
import "./EditClassDetails.css";

function EditClassTeacherDetails({
  currentTeacherName,
  classOwnerName,
  teachers,
  setNewClassTeacherId,
  setClassOwnerChangeReason,
  setShowChangeReasonDropdown,
  showChangeReasonDropdown,
  changeReasons,
  setChangeReasons,
  selectedReason,
  setSelectedReason,
}) {
  // Fetch the class ownership change reasons
  useEffect(() => {
    const fetchChangeReasons = async () => {
      try {
        const reasons = await getClassOwnerChangeReasons();
        setChangeReasons(reasons);
      } catch (error) {
        console.error("Error fetching class ownership change reasons:", error);
      }
    };

    fetchChangeReasons();
  }, []);

  // Handle dropdown change for new teacher
  const handleTeacherChange = (e) => {
    const selectedTeacherId = e.target.value;
    setNewClassTeacherId(selectedTeacherId);

    // Show or hide the change reason dropdown based on selection
    if (selectedTeacherId) {
      setShowChangeReasonDropdown(true);
    } else {
      setShowChangeReasonDropdown(false);
      setSelectedReason({ id: "", reason: "" }); // Reset reason when no teacher is selected
    }
  };

  // Handle change reason selection
  const handleReasonChange = (event) => {
    const selectedValue = event.target.value;
    const reason = changeReasons.find((reason) => reason.id === selectedValue); // Find the reason object
    setSelectedReason(reason || { id: "", reason: "" }); // Save both the ID and reason text
    setClassOwnerChangeReason(reason ? reason.reason : ""); // Pass the reason text back to the parent

    // Log the selected reason and ID
    console.log("Selected Reason ID:", reason ? reason.id : "None");
    console.log("Selected Reason Text:", reason ? reason.reason : "None");
  };

  // Tooltip content
  const tooltip = (
    <Tooltip id="teacher-tooltip">
      If the new teacher isn't listed, please go to the "Invite Colleagues"
      section on the homepage and invite them before creating their class! If
      you have already invited them, please ensure they have created their
      account first before creating their classroom!
    </Tooltip>
  );

  // Determine the old class teacher to display
  const oldClassTeacherName = classOwnerName || currentTeacherName;

  return (
    <Form className="form">
      {/* Old Class Teacher Field */}
      <Form.Group as={Row} className="mb-3" controlId="old-class-teacher">
        <Form.Label className="label" column sm="4">
          <p>Current Class Teacher</p>
        </Form.Label>
        <Col sm="8">
          <Form.Control
            placeholder={oldClassTeacherName}
            size="md"
            type="text"
            disabled
          />
        </Col>
      </Form.Group>

      {/* New Class Teacher Field */}
      <Form.Group as={Row} className="mb-3" controlId="new-class-teacher">
        <Form.Label className="label" column sm="4">
          <p>New Class Teacher</p>
        </Form.Label>
        <Col sm="7">
          <Form.Control
            className="teacher-dropdown"
            as="select"
            size="md"
            onChange={handleTeacherChange}
          >
            <option value="">Select a Teacher</option>
            {teachers.map((teacher) => (
              <option key={teacher.id} value={teacher.id}>
                {teacher.name}
              </option>
            ))}
          </Form.Control>
        </Col>
        <Col sm="1">
          {/* Tooltip Icon */}
          <OverlayTrigger placement="right" overlay={tooltip}>
            <FontAwesomeIcon
              icon={faInfoCircle}
              className="fa-2x"
              style={{ cursor: "pointer", color: "red" }}
            />
          </OverlayTrigger>
        </Col>
      </Form.Group>

      {/* Conditionally display dropdown for class ownership change reason */}
      {showChangeReasonDropdown && (
        <Form.Group
          as={Row}
          className="mb-3"
          controlId="class-owner-change-reason"
        >
          <Form.Label className="label" column sm="4">
            <p>Reason for Change</p>
          </Form.Label>
          <Col sm="8">
            <Form.Control
              as="select"
              value={selectedReason} // Set to empty string if id is undefined
              onChange={handleReasonChange}
              className="teacher-dropdown"
            >
              <option value="">Select a reason</option>
              {changeReasons.map((reason) => (
                <option key={reason.id} value={reason.id}>
                  {reason.reason}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>
      )}
    </Form>
  );
}

export default EditClassTeacherDetails;
