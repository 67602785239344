import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { toast, ToastContainer } from "react-toastify";
import { getSchools, whoami } from "../services/APIClient";
import "react-toastify/dist/ReactToastify.css";

function ConfirmClassOwnerModal({
  show,
  onConfirm,
  onClose,
  teacherOptions,
  onSelectOwner,
  handleInviteColleagueClick,
}) {
  const [showTeacherDropdown, setShowTeacherDropdown] = useState(false);
  const [selectedTeacher, setSelectedTeacher] = useState("");

  const handleNoClick = () => {
    setShowTeacherDropdown(true);
  };

  const handleBackClick = () => {
    setShowTeacherDropdown(false);
  };

  const handleConfirm = () => {
    if (showTeacherDropdown && selectedTeacher) {
      onSelectOwner(selectedTeacher);
      localStorage.setItem("selectedTeacherId", selectedTeacher);
      onConfirm(selectedTeacher);
      toast.success("Class has been successfully created!");
    } else {
      onSelectOwner("");
      localStorage.removeItem("selectedTeacherId");
      onConfirm("");
      toast.success("Class has been successfully created!");
    }
  };

  const handleSelectChange = (e) => {
    const selectedId = e.target.value;
    setSelectedTeacher(selectedId);
    localStorage.setItem("selectedTeacherId", selectedId);
  };

  return (
    <>
      <Modal show={show} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Class Ownership</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!showTeacherDropdown ? (
            <p style={{ fontSize: "24px" }}>Are you the classroom teacher?</p>
          ) : (
            <>
              <p style={{ fontSize: "18px", fontWeight: "bold" }}>
                Please select the class teacher from the list. If the teacher
                isn't listed, please click the "Invite Colleague" button below
                to invite them before creating their class!
              </p>
              <Form.Control
                as="select"
                value={selectedTeacher}
                onChange={handleSelectChange}
              >
                <option value="">Select teacher...</option>
                {teacherOptions.map((teacher) => (
                  <option key={teacher.id} value={teacher.id}>
                    {teacher.name}
                  </option>
                ))}
              </Form.Control>
              <button
                className="blue-button"
                style={{ marginTop: "10px" }}
                onClick={handleInviteColleagueClick}
              >
                Invite Colleague
              </button>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          {!showTeacherDropdown && (
            <button className="white-button" onClick={handleNoClick}>
              No
            </button>
          )}
          {showTeacherDropdown && (
            <button className="white-button" onClick={handleBackClick}>
              Back
            </button>
          )}
          <button className="blue-button" onClick={handleConfirm}>
            {showTeacherDropdown ? "Confirm" : "Yes"}
          </button>
        </Modal.Footer>
      </Modal>

      {/* Toast Notification */}
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default ConfirmClassOwnerModal;
