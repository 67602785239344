import React, { useMemo, useState, useEffect, useRef } from "react";
import ReactToPrint from "react-to-print";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Accordion, Tabs, Tab } from "react-bootstrap";
import {
  getClass,
  getClassAssessments,
  getTeachers,
  getResultsByStudent,
} from "../services/APIClient";
import { getIndividualStudentResults } from "../services/APIClient";
import SencoAssessmentDropdown from "./SencoAssessmentDropdown";
import SencoStudentsResultsTable from "./SencoStudentResultsTable";
import RecommendationsButton from "./RecommendationsButton";
import ReturnToSencoResults from "./ReturnToSencoResults";
import SencoTaskScoreGraph from "./SencoTaskScoreGraph";
import PieThresholdGraph from "./PieThresholdGraph";
import ClassroomAnalysis from "./ClassroomAnalysis";
import TaskScoresComparisonChart from "./TaskScoresComparisonChart";
import StudentPerformancePieChart from "./StudentPerformancePieChart";
import AllStudentsPerformancePieChart from "./AllStudentsPerformancePieChart";
import AllStudentsIndividualSearch from "./AllStudentsIndividualSearch";
import StandardScoresGraph from "./StandardScoresGraph";
import ScoreStatements from "./ScoreStatements";
import CandyBearAnalysisGraph from "./CandyBearAnalysisGraph";
import ScoreInterpretationTable from "./ScoreInterpretationTable";
import TaskDetailChart from "./TaskDetailChart";
import StudentPerformancePrintViewAlphabetical from "./StudentPerformancePrintViewAlphabetical";

import "./SencoStudentsResultsOverview.css";

function SencoStudentsResultsOverview(props) {
  const {
    classesResults = {},
    toggleScoreType,
    showAtRiskOnly,
    schoolid,
    teacherid,
    classIds,
    allClassesAssessments,
    assessment,
  } = props;

  const [classDetails, setClassDetails] = useState(null);
  const [classAssessments, setClassAssessments] = useState(null);
  const [teachers, setTeachers] = useState(null);
  const [gradeLevel, setGradeLevel] = useState(null);
  const [selectedChild, setSelectedChild] = useState(null);
  const [selectedChildID, setSelectedChildID] = useState("");
  const [individualStudentResult, setIndividualStudentResult] = useState("");
  const [selectedTask, setSelectedTask] = useState(null);
  const [selectedTaskData, setSelectedTaskData] = useState(null);
  const [isTaskDetailVisible, setIsTaskDetailVisible] = useState(false);
  const [showPrintView, setShowPrintView] = useState(false);
  const [showStudentPerformancePrintView, setShowStudentPerformancePrintView] =
    useState(false);
  const [showChildPrintOptions, setShowChildPrintOptions] = useState(false);
  const [selectedAssessment, setSelectedAssessment] = useState(assessment); // Default to the passed 'assessment' prop

  const classRefs = useRef({});
  const individualChildRef = useRef();
  const candyBearRef = useRef();
  const standardScoreGraphRef = useRef();
  const studentPerformancePieRef = useRef();
  const performanceTableRef = useRef();

  let name = "Results";

  useEffect(() => {
    if (assessment && assessment.instances) {
      getResultsByStudent(assessment.instances).then((response) => {
        setAssessmentResultsByStudent(response);
      });
    }
  }, [assessment]);

  const navigate = useNavigate();

  const [assessmentResultsByStudent, setAssessmentResultsByStudent] = useState(
    []
  );

  let fromSencoResults = true;

  useEffect(() => {
    getTeachers(schoolid).then((response) => setTeachers(response));
  }, [schoolid]);

  useEffect(() => {
    if (selectedChildID) {
      getIndividualStudentResults(selectedChildID)
        .then((response) => {
          setIndividualStudentResult(response);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [selectedChildID]);

  const handleTaskClick = (taskName) => {
    // Filter classes to get only those with a score for the selected task
    const taskData = formattedClassData
      .map((classData) => ({
        className: classData.name,
        score: classData.taskScores[taskName],
      }))
      .filter((data) => data.score !== undefined); // Filter out classes without scores

    // Toggle visibility
    if (selectedTask === taskName) {
      setIsTaskDetailVisible(!isTaskDetailVisible); // Toggle visibility
      setSelectedTask(null); // Reset the selected task
      setSelectedTaskData(null); // Reset the selected task data
    } else {
      setSelectedTask(taskName);
      setSelectedTaskData(taskData);
      setIsTaskDetailVisible(taskData.length > 0); // Set visibility based on data length
    }
  };

  const thresholds = {
    Rhyming: { task: 20, student: 25 },
    "Initial Phoneme": { task: 20, student: 20 },
    "Phoneme Blending": { task: 20, student: 20 },
    "Letter Name": { task: 20, student: 15 },
    Deletion: { task: 20, student: 20 },
    "Letter Sound": { task: 20, student: 20 },
    "Word Recognition": { task: 10, student: 15 },
    "Non-Word Recognition": { task: 10, student: 15 },
  };

  const formattedClassData = useMemo(() => {
    const classScores = [];

    Object.entries(classesResults).forEach(([classId, classObj]) => {
      const students = classObj.students || {};
      const classTaskScores = {};

      Object.entries(students).forEach(([studentId, student]) => {
        // Change to Object.entries to get both ID and student object
        const studentResults = student.results || [];
        studentResults.forEach((result) => {
          const tasks = result.tasks || [];
          tasks.forEach((task) => {
            const taskName = task.name;
            const score = toggleScoreType
              ? task.groupPercentile
              : task.score * 100;

            if (!classTaskScores[taskName]) {
              classTaskScores[taskName] = [];
            }
            classTaskScores[taskName].push(score);
          });
        });
      });

      // Calculate average scores for the class
      Object.keys(classTaskScores).forEach((taskName) => {
        const scores = classTaskScores[taskName];
        const averageScore = scores.reduce((a, b) => a + b, 0) / scores.length;
        classTaskScores[taskName] = averageScore.toFixed(2);
      });

      // Ensure assessmentResults contains the student-specific results
      classScores.push({
        id: classId,
        name: classObj.name,
        taskScores: classTaskScores,
        assessmentResults: Object.entries(students).flatMap(
          ([studentId, student]) => {
            // Log the student object before mapping

            return student.results
              ? student.results.map((result) => {
                  // Log the result structure

                  return {
                    student: {
                      name: student.name,
                      id: studentId, // Use the key directly for the student ID
                    },
                    tasks: result.tasks || [],
                  };
                })
              : [];
          }
        ),
      });
    });

    return classScores; // Return the formatted array
  }, [classesResults, toggleScoreType]);

  const allStudentsResults = useMemo(() => {
    return Object.values(classesResults)
      .flatMap((classObj) =>
        Object.entries(classObj.students).map(([id, student]) => ({
          student: { name: student.name, id: id },
          tasks: student.results
            ? student.results.flatMap((result) => result.tasks || [])
            : [],
        }))
      )
      .filter((student) => student.tasks.length > 0); // Filter out students with no tasks
  }, [classesResults]);

  const averageTaskScores = useMemo(() => {
    const classAverages = {}; // Object to hold average scores for each task by class

    Object.entries(classesResults).forEach(([classId, classObj]) => {
      const students = classObj.students || {};
      const taskScores = {}; // To accumulate scores for this class

      Object.values(students).forEach((student) => {
        const studentResults = student.results || [];
        studentResults.forEach((result) => {
          const tasks = result.tasks || [];
          tasks.forEach((task) => {
            const taskName = task.name;
            const score = toggleScoreType
              ? task.groupPercentile
              : task.score * 100;

            // Initialize the task entry if it doesn't exist
            if (!taskScores[taskName]) {
              taskScores[taskName] = { total: 0, count: 0 };
            }

            // Accumulate the total score and count for each task
            taskScores[taskName].total += score;
            taskScores[taskName].count += 1;
          });
        });
      });

      // Calculate the average score for each task for this class
      const averages = {};
      Object.entries(taskScores).forEach(([taskName, { total, count }]) => {
        averages[taskName] = (total / count).toFixed(2); // Store the average score
      });

      classAverages[classId] = {
        className: classObj.name,
        averages: averages,
      };
    });

    return classAverages;
  }, [classesResults, toggleScoreType]);

  useEffect(() => {
    if (formattedClassData.length > 0) {
      const classId = formattedClassData[0].id; // Extract the classId

      getClass(classId).then((response) => {
        setClassDetails(response);
        setGradeLevel(response.grade_level);
      });

      getClassAssessments(classId).then((response) => {
        setClassAssessments(response);
      });
    }
  }, [formattedClassData]);

  return (
    <div className="class-overview-container">
      <Container className="container-shape">
        <Row className="page-description-bar">
          <Col className="col-6">
            <h2 className="page-description-header">SENCO Results Overview</h2>
            <p>Click on a class to view individual student results.</p>
          </Col>
          <Row>
            <Col className="col-5">
              <h4 className="page-description-header">{assessment.name}</h4>
            </Col>
            <Col className="col-1"></Col>
            <Col className="col-6">
              <div className="d-flex align-items-center">
                <h4 className="page-description-header">Select Assessment</h4>
                <SencoAssessmentDropdown
                  classAssessments={classAssessments}
                  classIds={classIds}
                  teachers={teachers}
                  assessmentName={assessment.name}
                  assessment={assessment}
                  teacherid={teacherid}
                  schoolid={schoolid}
                  gradeLevel={gradeLevel}
                  allClassesAssessments={allClassesAssessments}
                />
              </div>
            </Col>
          </Row>
          <Col className="col-2">
            <ReturnToSencoResults />
          </Col>
          <Col className="col-4">
            <RecommendationsButton onResultsPage={true} />
          </Col>
        </Row>

        <Tabs
          defaultActiveKey="overview"
          id="senco-results-tabs"
          className="mb-3"
        >
          {/* First Tab with the Accordion */}
          <Tab eventKey="overview" title="Class Results">
            <Row>
              <Col className="col-5">
                <ScoreInterpretationTable />
              </Col>
            </Row>
            <Accordion defaultActiveKey="0">
              {Object.keys(classesResults).map((classId) => {
                const className = classesResults[classId].name;

                if (className.toUpperCase().includes("SET")) {
                  return null;
                }

                return (
                  <Accordion key={classId}>
                    <Accordion.Header>{className}</Accordion.Header>
                    <Accordion.Body>
                      <SencoStudentsResultsTable
                        classesResults={{ [classId]: classesResults[classId] }} // Pass only the relevant class results
                        toggleScoreType={props.toggleScoreType}
                        showAtRiskOnly={showAtRiskOnly}
                        schoolid={schoolid}
                        teacherid={teacherid}
                        classId={classId}
                        assessment={assessment}
                        classDetails={classDetails}
                        classAssessments={classAssessments}
                        teachers={teachers}
                        gradeLevel={gradeLevel}
                        assessmentResultsByStudent={assessmentResultsByStudent}
                        fromSencoResults={fromSencoResults}
                      />
                    </Accordion.Body>
                  </Accordion>
                );
              })}
            </Accordion>
          </Tab>

          {/* Performance Overview Tab */}
          <Tab eventKey="performance" title="Class Analysis">
            <Accordion defaultActiveKey="0">
              {Object.keys(formattedClassData).map((classId) => {
                const className = formattedClassData[classId].name;

                if (className.toUpperCase().includes("SET")) {
                  return null;
                }

                return (
                  <Accordion key={classId}>
                    <Accordion.Header>
                      {formattedClassData[classId].name}
                    </Accordion.Header>
                    <Accordion.Body>
                      {/* Render TaskScoreGraph for the current class */}
                      <SencoTaskScoreGraph
                        tasksData={formattedClassData[classId].taskScores}
                        onLabelClick={handleTaskClick}
                        className={formattedClassData[classId].name}
                      />
                      {selectedTask && selectedTaskData && (
                        <TaskDetailChart
                          taskName={selectedTask}
                          data={selectedTaskData}
                          className={formattedClassData[classId].name}
                        />
                      )}
                      {/* Render PieThresholdGraph for the current class */}
                      <PieThresholdGraph
                        assessmentResults={
                          formattedClassData[classId].assessmentResults
                        }
                        thresholds={thresholds}
                        fromSencoResults={true}
                        className={formattedClassData[classId].name}
                      />
                      <hr />
                      {/* Check if assessmentResults is defined and not empty before rendering ClassroomAnalysis */}
                      {formattedClassData[classId].assessmentResults.length >
                        0 && (
                        <ClassroomAnalysis
                          assessmentResultsByStudent={
                            formattedClassData[classId].assessmentResults
                          }
                          thresholds={thresholds}
                        />
                      )}
                    </Accordion.Body>
                  </Accordion>
                );
              })}
            </Accordion>
          </Tab>

          {/* Recommendations Tab */}
          <Tab eventKey="recommendations" title="Class Insights">
            <Accordion defaultActiveKey="0">
              {Object.keys(formattedClassData).map((classId) => {
                const className = formattedClassData[classId].name;

                if (className.toUpperCase().includes("SET")) {
                  return null;
                }

                return (
                  <Accordion key={classId}>
                    <Accordion.Header>
                      {formattedClassData[classId].name}
                    </Accordion.Header>
                    <Accordion.Body>
                      <TaskScoresComparisonChart
                        assessmentResultsByStudent={
                          formattedClassData[classId].assessmentResults
                        }
                        fromSencoResults={true}
                        className={formattedClassData[classId].name}
                      />
                    </Accordion.Body>
                  </Accordion>
                );
              })}
            </Accordion>
          </Tab>

          {/* Settings Tab */}
          <Tab eventKey="settings" title="Planning SET">
            <Accordion defaultActiveKey="0">
              {Object.keys(formattedClassData).map((classId) => {
                // Create a ref for each class's performance pie chart
                classRefs.current[classId] =
                  classRefs.current[classId] || React.createRef();
                const className = formattedClassData[classId].name;

                if (className.toUpperCase().includes("SET")) {
                  return null;
                }

                return (
                  <Accordion key={classId} eventKey={classId}>
                    <Accordion.Header>
                      {formattedClassData[classId].name}
                    </Accordion.Header>
                    <Accordion.Body>
                      <Row>
                        <Col className="blue-button-container">
                          <button
                            className="blue-button"
                            onClick={() => {
                              setShowPrintView(!showPrintView);
                              setShowStudentPerformancePrintView(
                                !showPrintView
                              ); // Show print view when toggling
                            }}
                          >
                            {showPrintView
                              ? "Hide Alphabetical Print View"
                              : "View Alphabetical Print View"}
                          </button>
                        </Col>
                      </Row>
                      {showStudentPerformancePrintView && (
                        <>
                          <ReactToPrint
                            trigger={() => (
                              <button className="blue-button">
                                Print Alphabetical View
                              </button>
                            )}
                            content={() => classRefs.current[classId].current}
                          />
                          <div ref={classRefs.current[classId]}>
                            <StudentPerformancePrintViewAlphabetical
                              assessmentResultsByStudent={
                                formattedClassData[classId].assessmentResults
                              }
                              thresholds={thresholds}
                            />
                          </div>
                        </>
                      )}
                      {!showPrintView && (
                        <StudentPerformancePieChart
                          assessmentResultsByStudent={
                            formattedClassData[classId].assessmentResults
                          }
                          thresholds={thresholds}
                          classDetails={formattedClassData[classId]}
                          classAssessments={classAssessments}
                          gradeLevel={gradeLevel}
                          teachers={teachers}
                          assessment={assessment}
                          toggleScoreType={toggleScoreType}
                          schoolid={schoolid}
                          teacherid={teacherid}
                          fromSencoResults={fromSencoResults}
                        />
                      )}
                    </Accordion.Body>
                  </Accordion>
                );
              })}
            </Accordion>
            <hr />
            <Accordion>
              <Accordion.Header>All Students Performance</Accordion.Header>
              <Accordion.Body>
                <AllStudentsPerformancePieChart
                  allStudentsResults={allStudentsResults}
                  thresholds={thresholds}
                  classDetails={formattedClassData}
                  classAssessments={classAssessments}
                  gradeLevel={gradeLevel}
                  teachers={teachers}
                  assessment={assessment}
                  toggleScoreType={toggleScoreType}
                  schoolid={schoolid}
                  teacherid={teacherid}
                  fromSencoResults={fromSencoResults}
                />
              </Accordion.Body>
            </Accordion>
          </Tab>

          {/* Search Tab */}
          <Tab eventKey="results3" title="Individual Child">
            <button
              className="blue-button"
              onClick={() => setShowChildPrintOptions(!showChildPrintOptions)}
            >
              {showChildPrintOptions
                ? "Hide Print Options"
                : "View Print Options"}
            </button>
            {showChildPrintOptions && (
              <div className="print-options">
                <ReactToPrint
                  trigger={() => (
                    <button className="blue-button">
                      Print Individual Child
                    </button>
                  )}
                  content={() => individualChildRef.current}
                />
                <ReactToPrint
                  trigger={() => (
                    <button className="blue-button">
                      Print Standard Scores Graph
                    </button>
                  )}
                  content={() => standardScoreGraphRef.current}
                />
                <ReactToPrint
                  trigger={() => (
                    <button className="blue-button">
                      Print Progress Between Testing Points
                    </button>
                  )}
                  content={() => candyBearRef.current}
                />
              </div>
            )}
            <AllStudentsIndividualSearch
              allStudentsResults={allStudentsResults}
              selectedChild={selectedChild}
              setSelectedChild={setSelectedChild}
            />
            <hr></hr>
            <div ref={individualChildRef}>
              <div ref={standardScoreGraphRef}>
                <StandardScoresGraph selectedChild={selectedChild} />
                <ScoreStatements selectedChild={selectedChild} />
              </div>
              <hr></hr>
              <div ref={candyBearRef}>
                <CandyBearAnalysisGraph
                  selectedChild={selectedChild}
                  setSelectedChildID={setSelectedChildID}
                  individualStudentResult={individualStudentResult}
                />
              </div>
            </div>
          </Tab>
        </Tabs>
      </Container>
    </div>
  );
}

export default SencoStudentsResultsOverview;
