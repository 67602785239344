import React, { useState, useEffect } from "react";

// Utility function to compute analysis
const analyzeClassroom = (assessmentResultsByStudent, thresholds) => {
  const students = [];
  const tasks = Object.keys(thresholds);

  // Prepare student data
  assessmentResultsByStudent.forEach((result) => {
    // Ensure result contains a student object with tasks
    if (!result.student || !result.tasks) {
      console.warn("Invalid result structure:", result);
      return; // Skip invalid results
    }

    const student = {
      name: result.student.name,
      tasks: result.tasks.reduce((acc, task) => {
        acc[task.name] = {
          score: task.score * 100, // Convert score to percentage
          percentile: task.groupPercentile,
        };
        return acc;
      }, {}),
    };
    students.push(student);
  });

  // Compute struggling tasks
  const taskCount = tasks.reduce((acc, task) => {
    acc[task] = 0;
    return acc;
  }, {});

  students.forEach((student) => {
    tasks.forEach((task) => {
      if (
        student.tasks[task] &&
        student.tasks[task].score < thresholds[task].task
      ) {
        taskCount[task]++;
      }
    });
  });

  // Determine high and moderate likelihood recommendations
  const highLikelihoodRecommendations = [];
  const moderateLikelihoodRecommendations = [];
  const highLikelyCutOff = students.length * 0.5;
  const possibleCutOff = students.length * 0.25;

  tasks.forEach((task) => {
    if (taskCount[task] > highLikelyCutOff) {
      highLikelihoodRecommendations.push(task);
    } else if (taskCount[task] > possibleCutOff) {
      moderateLikelihoodRecommendations.push(task);
    }
  });

  return {
    highLikelihoodRecommendations,
    moderateLikelihoodRecommendations,
  };
};

const ClassroomAnalysis = ({ thresholds, assessmentResultsByStudent }) => {
  const recommendationsStyle = {
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    fontSize: "16px",
    maxWidth: "600px",
    textAlign: "center",
    margin: "20px auto", // Center horizontally with margin
    lineHeight: "1.5", // Line height for readability
  };
  const [analysisResults, setAnalysisResults] = useState({
    highLikelihoodRecommendations: [],
    moderateLikelihoodRecommendations: [],
  });

  useEffect(() => {
    if (assessmentResultsByStudent) {
      const results = analyzeClassroom(assessmentResultsByStudent, thresholds);
      setAnalysisResults(results);
    }
  }, [thresholds, assessmentResultsByStudent]);

  const { highLikelihoodRecommendations, moderateLikelihoodRecommendations } =
    analysisResults;

  return (
    <div style={recommendationsStyle}>
      <h2>Classroom Analysis</h2>
      <p>
        From analyzing the information we have gathered, there seems to be
        {highLikelihoodRecommendations.length > 0 && (
          <>
            {
              " a high likelihood that whole class teaching is needed in the area(s) of "
            }
            {highLikelihoodRecommendations.join(", ")}.
          </>
        )}
        {moderateLikelihoodRecommendations.length > 0 && (
          <>
            {
              " a moderate likelihood that whole class teaching is needed in the area(s) of "
            }
            {moderateLikelihoodRecommendations.join(", ")}.
          </>
        )}
      </p>
    </div>
  );
};

export default ClassroomAnalysis;
