import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";

import LeftPanel from "../components/LeftPanel";
import HeadingBanner from "../components/HeadingBanner";
import StudentDetailsOverview from "../components/StudentDetailsOverview";
import OrientationPrompt from "../components/OrientationPrompt";

function StudentDetails(props) {
  const location = useLocation();

  let name = "Student Details";
  let teacherid = location.state.classDetails.classDetails.teacherId;

  let initialClassDetails = location.state.classDetails.classDetails;
  let teachers = location.state.teachers.teachers;
  let student = location.state.student.student;
  let qrCodeDetails = location.state.qrCodeDetails.qrCodeDetails;
  let students = location.state.students.students;
  let gradeLevel = initialClassDetails.grade_level;
  let schoolid = location.state.schoolid.schoolid;
  let teacherRoles = location.state?.teacherRoles.teacherRoles;

  const [classDetails, setClassDetails] = useState(initialClassDetails); // Manage state here

  const [isPortrait, setIsPortrait] = useState(
    window.matchMedia("(orientation: portrait)").matches
  );

  useEffect(() => {
    const handleOrientationChange = () => {
      setIsPortrait(window.innerWidth < window.innerHeight);
    };

    window.addEventListener("resize", handleOrientationChange);

    return () => {
      window.removeEventListener("resize", handleOrientationChange);
    };
  }, []);

  const handleClassChange = (newClassDetails) => {
    setClassDetails(newClassDetails); // Update class details state
  };

  return (
    <Container fluid className="vh-100">
      <HeadingBanner
        isLoggedIn={true}
        cloud={true}
        name={name}
        teacherid={teacherid}
      />
      <Row className="h-100">
        <LeftPanel />
        <Col sm={{ span: 10, offset: 2 }} className="blue-background">
          <StudentDetailsOverview
            classDetails={classDetails} // Pass state
            teachers={teachers}
            student={student}
            gradeLevel={gradeLevel}
            qrCodeDetails={qrCodeDetails}
            students={students}
            teacherId={teacherid}
            onClassChange={handleClassChange}
            schoolid={schoolid}
            teacherRoles={teacherRoles}
          />
        </Col>
      </Row>
      {isPortrait && <OrientationPrompt />}
    </Container>
  );
}

export default StudentDetails;
