import { React, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import GradeCard from "./GradeCard";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import AlpacaInfoGesture from "./AlpacaInfoGesture";

import { getStudents } from "../services/APIClient";

import "./ClassCard.css";

function RetestTaskClassCard(props) {
  let classDetails = props.classDetails;
  let classStudents = props.classStudents;
  let name = classDetails.name;
  let gradeLevel = classDetails.grade_level;
  let classid = classDetails.id;

  let gradeLevel1 = classDetails.grade_level1;
  let gradeLevel2 = classDetails.grade_level2;
  let trialClass = classDetails.trial_class;

  let gradeLevelOptions = props.gradeLevelOptions;

  let schoolid = props.schoolid;
  let teacherid = props.teacherid;

  const [speechText, setSpeechText] = useState(null);
  let [studentsResponse, setStudentsResponse] = useState("");

  let teachers = props.teachers;

  // console.log(props)

  useEffect(() => {
    getStudents(classid).then((response) => {
      setStudentsResponse(response);
    });
    setSpeechText(
      `It looks like you need to add students to your first classroom still. Please go to "Admin" and into "My Classes" to do so.`
    );
  }, [classid]);

  let studentsArr = [];
  let students = [
    ...(studentsResponse.students || []),
    ...(studentsResponse.additionalStudents || []),
  ];

  if (students === undefined) {
    console.log("No students here");
  } else studentsArr.push(students);

  return (
    <>
      <Col className="col-4">
        <Card className={`${trialClass ? "trial-card-detail" : "card-detail"}`}>
          <Link
            className="link"
            to={`/retesttaskdetail`}
            state={{
              classDetails: { classDetails },
              teachers: { teachers },
              name: { name },
              gradeLevel: { gradeLevel },
              gradeLevelOptions: { gradeLevelOptions },
              schoolid: { schoolid },
              teacherid: { teacherid },
            }}
          >
            <img
              src="./assets/imgs/Asset-class.webp"
              className="class-card-img"
            ></img>
            <div>
              <p
                className={`${
                  gradeLevel1 ? "grade-level-blue" : "grade-level-purple"
                }`}
              >
                {gradeLevel}
              </p>
              {students?.length !== undefined ? (
                <p className="class-card-text">
                  <img
                    src="./assets/imgs/child.png"
                    className="class-card-img"
                    alt="Student Image"
                  />
                  x {students.length}
                </p>
              ) : (
                <>
                  <br></br>
                  <p className="class-card-text">
                    Please add students to your class
                  </p>
                </>
              )}
            </div>
            <p
              className={`${
                trialClass ? "trial-class-card-text" : "class-card-text"
              }`}
            >
              {name}
            </p>
          </Link>
        </Card>
      </Col>
    </>
  );
}
export default RetestTaskClassCard;
