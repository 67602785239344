import React from "react";
import { BsPlusCircle } from "react-icons/bs";
import { Link } from "react-router-dom";
import "./ClassCard.css";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

function CreateSETClassCard(props) {
  let name = props.name;
  let schoolid = props.schoolid;
  let teacherid = props.teacherid;
  let classes = props.classes;

  return (
    <Col className="col-4">
      <Card className="set-card-detail">
        <Link
          className="link"
          to="/createsetclass"
          state={{
            name: { name },
            schoolid: { schoolid },
            teacherid: { teacherid },
            classes: { classes },
          }}
        >
          <BsPlusCircle size={65} className="set-class-card-img" />
          <p className="set-class-card-text">Create a SET Class!</p>
        </Link>
      </Card>
    </Col>
  );
}

export default CreateSETClassCard;
